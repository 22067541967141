<template>
  <div class="list-container">
    <progress-indicator-modal
      id="talkscript-saving-indicator-202102151441"
      :loading="saving"
      :loading-label="staticText.saving"
      :ignore-timeout="true"
    >
    </progress-indicator-modal>

    <!-- Header -->
    <div>
      <!-- Title -->
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="title page-header">
          {{ staticText.playbookConfiguratorTitle }}
        </div>

        <div class="d-flex align-items-center">

          <!-- Save buttons and controls -->
          <b-button id="playbook-control-menu"
                    class="mr-2"
          >
            <img src="/img/icons/points-icon.svg"/>
          </b-button>
          <b-popover target="playbook-control-menu"
                     triggers="focus"
                     placement="bottom"
                     custom-class="bao-popover"
          >
            <b-list-group class="playbook-options">
              <!-- Duplicate -->
              <b-list-group-item id="save-as-btn-202101231105"
                                 class="d-flex no-border p-1 mb-1 align-items-center justify-content-between"
                                 v-if="playbook.id"
                                 button
                                 :disabled="processingPlaybook"
                                 @click.prevent="showDuplicateModal = true"
              >
                {{ staticText.duplicateBtn }}
                <img src="@/assets/svgs/duplicate-icon.svg" class="mr-2"/>
              </b-list-group-item>

              <hr v-if="playbook.can_modify" class="w-100 my-auto"/>

              <!-- Set playbook as Template -->
              <b-list-group-item id="playbook-template-flag-202101231110"
                                 button
                                 v-if="playbook.can_modify"
                                 class="d-flex no-border p-1 mt-1 align-items-center justify-content-between"
                                 @click.prevent="toggleIsTemplate(playbook)"
              >
                {{ staticText.setTemplateLabel }}
                <b-form-checkbox id="template-checkbox-202101231110"
                                 switch
                                 v-model="playbook.is_template"
                >
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-popover>

          <!-- Preview -->
          <b-btn
            id="preview-playbook-btn-202424070949"
            variant="secondary"
            class="px-4 mr-2"
            @click="handlePreviewPlaybook"
          >
            {{ staticText.previewLabel }}
          </b-btn>

          <!-- Save -->
          <b-btn id="save-playbook-btn-202101231105"
                 :disabled="savingButtonDisabled"
                 variant="primary"
                 class="px-4"
                 @click="handleSavePlaybook"
          >
            <img src="../../../public/img/icons/light-save-icon.svg"/>
            {{ staticText.savePlaybookBtn }}
          </b-btn>
        </div>
      </div>
      <!-- Playbook title -->
      <bao-editable-text
        v-model="playbook.name"
        :disabled="!playbook.can_modify"
        :show-pen-icon="playbook.can_modify"
        :placeholder="staticText.playbookTitlePlaceholder"
        :extraStyle="{ 'max-width': '700px !important' }"
        :max-character-limit="120"
        :max-character-limit-message="staticText.playbookNameMaxCharacterLimitMessage"
        @input-changed="title => changePlaybookTitle(title, playbook)"
      ></bao-editable-text>
    </div>

    <!-- Playbook container-->
    <progress-indicator v-if="playbookLoading"></progress-indicator>

    <b-tabs v-else
            class="mt-3"
            active-nav-item-class="bg-transparent border-left-0 border-top-0 border-right-0 border-dark"
    >
      <!--Content tab-->
      <b-tab active
      >
        <template #title>
          <div class="d-flex align-items-center">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-1'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 1h5.625a.375.375 0 01.369.308l.006.067V7c0 .79.612 1.438 1.387 1.496l.113.004h5.625a.375.375 0 01.369.307l.006.068V17.5a1.5 1.5 0 01-1.387 1.496L16 19H4a1.5 1.5 0 01-1.496-1.387L2.5 17.5v-15c0-.79.612-1.438 1.388-1.496L4 1h5.625H4zm7.125 6V1.646a.113.113 0 01.164-.1l.028.021 5.616 5.616a.112.112 0 01.03.106.113.113 0 01-.075.08l-.035.006H11.5a.375.375 0 01-.369-.308L11.125 7V1.646 7z" fill="#35375B" opacity=".8"/></svg>
            {{ staticText.contentTabTitle }}
          </div>
        </template>
        <!-- Content tab container -->
        <div>
          <!-- Agenda toggle button -->
          <div>
            <bao-toggle-button
              id="agenda-toggle-btn-20230213843"
              :is-active="showAgenda"
              :type="'agenda'"
              :button-class="'agendaBtn'"
              class="agendaToggleBtn mx-1 p-0"
              @toggle="showAgenda=!showAgenda"
            ></bao-toggle-button>
            <b-tooltip
              target="agenda-toggle-btn-20230213843"
              :title="showAgenda ? staticText.hideAgendaLabel : staticText.showAgendaLabel"
              placement="left"
              boundary="viewport"
            ></b-tooltip>
          </div>
          <!-- Playbook content -->
          <div class="d-flex flex-grow-1 mt-3 playbook-container">
            <!-- Navigation panel -->
            <div :class="['agenda-menu', { 'closedAgenda' : !showAgenda }, {'pr-1': showAgenda}]">
              <playbook-items-navigation
                :disabled="!playbook.can_modify || playbookItemsLoading"
              ></playbook-items-navigation>
            </div>

            <!-- Middle container -->
            <div ref="middle-container-202127060526"
                 class="col pr-1 scrollspyClass nice-scrollbar"
            >
              <!-- Global Error/Info display -->
              <div id="global-info-div"
                   v-if="!!error || !playbook.can_modify"
                   class="text-center sticky-alert"
              >
                <b-alert id="global-permission-info-202117101745"
                         :show="!playbook.can_modify"
                         variant="info"
                >
                  {{ staticText.playbookPermissionMessage }}
                </b-alert>
                <base-alert
                  id="global-error-202117101745"
                  :alert-message="error"
                  :show-alert="!!error"
                  :dismissible="true"
                  variant="danger"
                  @dismissed="resetGlobalError"
                ></base-alert>
              </div>

              <!-- Playbook item container -->
              <div id="playbook-items-container-202106270720">

                <!-- playbook Item Container -->
                <playbook-configurator-item
                  v-if="!!rootContainer"
                  :playbook-items="rootContainer.children"
                  :main-container="rootContainer"
                  :item-types="playbookItemTypes()"
                  :can-modify="playbook.can_modify"
                  @items-changed="items => setPlaybookItems(items)"
                  @item-changed="itemWasChanged"
                  @update-last-saved="updateLastSaved"
                  @error="error => handleError(error)"
                >
                </playbook-configurator-item>

                <!-- Create/Link item buttons -->
                <new-item-controls
                  v-if="playbook.can_modify"
                  :playbook-items="playbookItems"
                  :main-container-id="rootContainer.id"
                  @create="addNewPlaybookItemAtEnd(rootContainer, playbookItems)"
                  @link="linkLibraryItemToPlaybook"
                >
                </new-item-controls>
              </div>
            </div>

            <!-- Shortcut Container -->
            <div class="pl-1 w-25">
              <div id="playbook-objection-container-202101231128"
                   class="h-100"
              >
                <!-- Shortcut list -->
                <shortcuts-configurator
                  v-if="rootContainer"
                  :main-container="rootContainer"
                  :can-modify="playbook.can_modify"
                  @input="objections => handleObjectionsChanged(objections)"
                  @error="error => handleError(error)"
                ></shortcuts-configurator>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <!--Settings tab-->
      <b-tab>
        <template #title>
          <div class="d-flex align-items-center">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-1'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.324 7.23A8.98 8.98 0 013.22 3.97a.533.533 0 01.573-.143l2.04.726a1.065 1.065 0 001.403-.807l.39-2.125a.531.531 0 01.411-.423 9.11 9.11 0 013.78 0 .53.53 0 01.412.423l.39 2.125a1.063 1.063 0 001.402.807l2.04-.726a.533.533 0 01.574.142c.853.94 1.5 2.051 1.893 3.26a.528.528 0 01-.162.567l-1.653 1.397a1.054 1.054 0 000 1.614l1.653 1.397c.165.14.228.363.162.567a8.98 8.98 0 01-1.893 3.26.532.532 0 01-.573.142l-2.04-.726a1.065 1.065 0 00-1.403.807l-.39 2.125a.53.53 0 01-.413.423 9.101 9.101 0 01-3.779 0 .531.531 0 01-.412-.423l-.389-2.125a1.061 1.061 0 00-1.403-.807l-2.04.726a.532.532 0 01-.573-.142 8.98 8.98 0 01-1.893-3.26.528.528 0 01.162-.567l1.653-1.397a1.056 1.056 0 000-1.614L1.488 7.796a.528.528 0 01-.162-.567h-.002zM7.3 10a2.625 2.625 0 105.251 0 2.625 2.625 0 00-5.25 0z" fill="#35375B" opacity=".8"/></svg>
            {{ staticText.settingsTabTitle }}
          </div>
        </template>
        <div v-if="playbook.can_modify"
             class="settings-container"
        >
          <!--Audio Language Selector-->
          <div v-if="canUseBaoAudio"
               class="settings-container__audio-input-selector mb-4"
          >
            <div class="font-weight-bold">{{ staticText.languageSettingsTitle }}</div>
            <AudioInputLanguageSelector
              :pre-selected-language="playbook.language"
              class="mt-2"
              :allow-empty="true"
              :label="staticText.playbookLanguageSelectLabel"
              :placeholder="staticText.playbookLanguageSelectPlaceholder"
              @select="selection => selectTranscriptLanguage(selection, playbook)"
            />
          </div>
          <!--Playbook Share Settings-->
          <div v-if="playbook" class="mt-2">
            <PlaybookAccessSettings :playbook="playbook"/>
          </div>
        </div>
        <div v-else>
          <b-alert id="global-permission-info-202117101745"
                   variant="info"
                   class="mt-2"
                   show
          >
            {{ staticText.playbookPermissionMessage }}
          </b-alert>
        </div>

      </b-tab>
    </b-tabs>

    <!-- All Modals used in this component -->

    <!-- Duplicate Modal -->
    <PlaybookDuplicateModal
      v-model="showDuplicateModal"
      :playbook="playbook"
      :edit-mode="true"
      :is-playbook-invalid="isPlaybookInvalid"
      @validatePlaybook="validatePlaybook(rootContainer)"
      @close="showDuplicateModal=false"
    />

    <!-- Modal to show warning on navigation-->
    <navigation-warning-modal
      v-model="showWarningDialog"
      :title="staticText.navigationModalTitle"
      @cancel="showWarningDialog=false"
      @ok="saveAndNavigate"
    >
      <p>
        {{ staticText.warningMessage }}
      </p>
    </navigation-warning-modal>

    <!-- Confetti -->
    <transition name="fade">
      <base-confetti
        v-if="showCopilotConfetti"
        :emoji="copilotConfettiEmoji"
        :heading="staticText.confettiHeader"
        :subheading="staticText.confettiSubheader"
        @close-confetti="showCopilotConfetti = false"
      />
    </transition>
  </div>
</template>

<script>
import ShortcutsConfigurator from "./components/ShortcutsConfigurator"
import appInfo from "./index"
import axios from "axios"
import { tooltipDelay } from "@/config"
import { playbookServices } from "./PlaybookServices"
import { mapActions, mapGetters, mapMutations } from "vuex"
import ProgressIndicatorModal from "../base/ProgressIndicatorModal"
import ProgressIndicator from "../base/ProgressIndicator"
import PlaybookConfiguratorItem from "./PlaybookConfiguratorItem"
import NavigationWarningModal from "./components/NavigationWarningModal"
import PlaybookItemMixin from "./PlaybookItemMixin"
import NewItemControls from "@/apps/talkscript/components/NewItemControls"
import BaseAlert from "@/apps/base/BaseAlert"
import BaoEditableText from "@/apps/base/BaoEditableText"
import PlaybookItemsNavigation from "@/apps/talkscript/components/PlaybookItemsNavigation"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import BaseConfetti from "../confetti/BaseConfetti"
import PlaybookDuplicateModal from "@/apps/talkscript/components/PlaybookDuplicateModal"
import CallSetupMixin from "@/apps/call/CallSetupMixin"
import AudioInputLanguageSelector from "@/apps/user/profile/AudioInputLanguageSelector"
import PlaybookAccessSettings from "@/apps/talkscript/components/PlaybookAccessSettings"

const getDefaultData = () => {
  return {
    appInfo,
    tooltipDelay,
    axios,
    staticTextDefault: {
      playbookConfiguratorTitle: "Playbook Configurator",
      savePlaybookBtn: "Save Playbook",
      previewLabel: "Preview",
      duplicateBtn: "Duplicate Playbook",
      playbookTitlePlaceholder: "New Playbook Name",
      loading: "Loading ...",
      setTemplateLabel: "Set as Template",
      navigationModalTitle: "Warning",
      warningMessage: "There was an error in saving playbook. Are you sure you want to navigate?",
      item: "Item",
      addNewItemError: "Error occurred while adding new item. <br/>",
      playbookPermissionMessage: "You do not have the rights to edit this playbook. " +
        "Please use ‘Duplicate Playbook’ to make a copy of the playbook",
      hideAgendaLabel: "Hide Agenda",
      showAgendaLabel: "Show Agenda",
      playbookNameMaxCharacterLimitMessage: "The playbook name cannot exceed 120 characters.",
      confettiHeader: "Hooray!",
      confettiSubheader: "Your playbook is ready - take a look right now!",
      contentTabTitle: "Content",
      settingsTabTitle: "Settings",
      languageSettingsTitle: "Standard Language for this Playbook",
      playbookLanguageSelectLabel: "Playbook Language",
      playbookLanguageSelectPlaceholder: "Select Playbook Language"
    },
    error: null,
    drag: false,
    items: [],
    objections: [],
    saving: false,
    loading: false,
    loadingInternal: true,
    showWarningDialog: false,
    toPath: null,
    discardError: false,
    showCopilotConfetti: false,
    copilotConfettiEmoji: "&#x1f929;"
  }
}

export default {
  name: "PlaybookConfigurator",
  mixins: [PlaybookItemMixin, CallSetupMixin],
  components: {
    PlaybookItemsNavigation,
    BaseAlert,
    PlaybookConfiguratorItem,
    ProgressIndicator,
    ProgressIndicatorModal,
    ShortcutsConfigurator,
    NavigationWarningModal,
    NewItemControls,
    BaoEditableText,
    BaoToggleButton,
    BaseConfetti,
    PlaybookDuplicateModal,
    AudioInputLanguageSelector,
    PlaybookAccessSettings
  },
  data () {
    return {
      ...getDefaultData(),
      showAgenda: true,
      showDuplicateModal: false,
      isPlaybookInvalid: false
    }
  },
  computed: {
    ...mapGetters({
      playbook: "playbookConfiguratorStore/getPlaybook",
      rootContainer: "playbookConfiguratorStore/getRootContainer",
      playbookItems: "playbookConfiguratorStore/getPlaybookItems",
      playbookLoading: "playbookConfiguratorStore/getPlaybookLoading",
      canUseBaoAudio: "auth/canUseBaoAudio"
    }),
    playbookId () {
      return this.$route.params.id
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    processingPlaybook () {
      return this.saving ||
        this.playbookLoading
    },
    savingButtonDisabled () {
      return !this.playbook.can_modify || this.processingPlaybook
    },
    playbookItemsLoading () {
      return this.playbookItems.some(item => item.loading)
    }
  },
  mounted () {
    // initialize playbook store and playbookConfigurator store.
    // make list of all playbooks available (required for additional playbooks, answer actions configurations etc...)
    this.fetchAllPlaybooks()
    // Get playbook data from API
    if (this.playbookId) {
      this.fetchPlaybook(this.playbookId)
    }
    if (this.$route.query["bao-copilot"]) {
      this.showCopilotConfetti = true
      setTimeout(() => {
        this.showCopilotConfetti = false
      }, 4500)
    }
  },
  beforeRouteLeave (to, from, next) {
    /**
     * If playbook is valid it executes all the pending operations for the playbook item
     * If it is invalid,  it warns user about the error
     **/
    const error = this.validatePlaybook(this.rootContainer)
    if (!error || this.discardError) {
      this.executeAllOperationsForPlaybook(this.playbook, this.rootContainer, this.playbookItems).then(() => {
        next()
      })
    } else {
      this.toPath = to
      this.showWarningDialog = true
    }
  },
  methods: {
    ...mapMutations({
      updatePlaybookItem: "playbookConfiguratorStore/updatePlaybookItem",
      setPlaybookItems: "playbookConfiguratorStore/setPlaybookItems",
      setPlaybookLoading: "playbookConfiguratorStore/setPlaybookLoading",
      addItemToPlaybookList: "playbookConfiguratorStore/addItemToPlaybookList",
      removeItemFromPlaybookList: "playbookConfiguratorStore/removeItemFromPlaybookList"
    }),
    ...mapActions({
      fetchPlaybook: "playbookConfiguratorStore/fetchPlaybook",
      fetchAllPlaybooks: "playbook/fetchAllPlaybooks"
    }),
    ...playbookServices.methods,
    selectTranscriptLanguage (selectedLanguage, playbook) {
      playbook.language = selectedLanguage ? selectedLanguage.value : null
      this.savePlaybook(playbook)
    },
    changePlaybookTitle (newTitle, playbook) {
      if (playbook.name !== newTitle) {
        playbook.name = newTitle
        this.savePlaybook(playbook)
      }
    },
    toggleIsTemplate (playbook) {
      playbook.is_template = !playbook.is_template
      this.savePlaybook(playbook)
    },
    handleError (errorData) {
      const errorMsg = this.extractErrorMessage(errorData.message)
      if (errorData.item && !errorMsg) {
        // If error is null and item is passed then reset error on global and item-level
        this.resetItemError(errorData.item)
        this.resetGlobalError()
      } else if (errorData.item && errorMsg) {
        // If error exists and item is passed then set error on global-level(with link to erroneous-item) and item-level
        this.setItemAndGlobalError(errorData.item, errorData.position, errorMsg)
      } else {
        // If item is not passed then set error on global-level as the error message passed
        this.showError(errorMsg)
      }
    },
    resetGlobalError () {
      this.error = null
    },
    validatePlaybookItems (itemArray) {
      const errorPositions = this.validatePlaybookChildren(itemArray)
      this.resetGlobalError()
      let globalErrorMsg = null
      if (errorPositions.length > 0) {
        globalErrorMsg = this.getGlobalErrorMsg(errorPositions)
        this.showError(globalErrorMsg)
      }
      return globalErrorMsg
    },
    itemWasChanged (item, itemPosition) {
      /**
       * This method validates and saves item, if item was provided else it only updates the playbook's
       * last saved value in the frontend
       **/
      const promise = !item ? Promise.resolve() : this.validateAndSaveItem(item, itemPosition)
      promise.then(() => {
        this.updateLastSaved()
      }).catch(error => {
        console.debug(error)
      })
    },
    validateAndSaveItem (item, itemPosition) {
      /**
       * This method checks if the item is valid and then sends the request to backend
       * itemPosition is required to show the position of the item in global error.
       **/
      return new Promise((resolve, reject) => {
        const error = this.validatePlaybookItem(item)
        if (error) {
          this.setGlobalError(item, itemPosition)
          reject(error)
          return
        }

        this.updatePlaybookItem({ item, index: itemPosition - 1 })
        this.handleSavePlaybookItem(item).then(() => {
          this.updateLastSaved()
          resolve()
        }).catch(error => {
          this.setItemAndGlobalError(item, itemPosition, this.extractErrorMessage(error))
          reject(error)
        })
      })
    },
    setItemAndGlobalError (item, itemPosition, error) {
      this.setItemError(item, error)
      this.setGlobalError(item, itemPosition)
    },
    setGlobalError (item, itemPosition) {
      const errorDetails = [{ position: itemPosition, itemLink: item.uniqueId }]
      const errorMsg = this.getGlobalErrorMsg(errorDetails)
      this.showError(errorMsg)
    },
    showError (error) {
      this.error = error
      this.setPlaybookLoading(false)
      this.saving = false
    },
    addNewPlaybookItemAtEnd (parent, playbookItems) {
      return new Promise((resolve, reject) => {
        this.createNewPlaybookAtEnd(parent, playbookItems)
          .then(() => {
            this.updateLastSaved()
            resolve()
          })
          .catch(error => {
            const errorMsg = this.staticText.addNewItemError + this.extractErrorMessage(error)
            this.showError(errorMsg)
            reject(errorMsg)
          })
      })
    },
    updateLastSaved () {
      this.playbook.updated_at = Date.now()
    },
    saveAndNavigate () {
      const that = this
      that.showWarningDialog = false
      that.discardError = true
      that.saving = true
      return that.executeAllOperationsForPlaybook(that.playbook, that.rootContainer, that.playbookItems).then(() => {
        that.$router.push(that.toPath)
      }).finally(() => {
        that.saving = false
      })
    },
    handleObjectionsChanged (objections) {
      this.rootContainer.objections = objections
      this.orderWasChanged()
    },
    async handlePreviewPlaybook () {
      await this.handleSavePlaybook()
      this.startCall(this.playbookId, { previewMode: true })
    },
    handleSavePlaybook () {
      const that = this
      return new Promise((resolve, reject) => {
        that.saving = true
        const error = that.validatePlaybook(that.rootContainer)
        if (error) {
          that.saving = false
          reject(error)
          return
        }
        that.executeAllOperationsForPlaybook(that.playbook, that.rootContainer, that.playbookItems).then(() => {
          // Not calling the updateLastSaved() here because the item-updates/rootContainer order-update will call
          // updateLastSaved() in their respective calls. Here we are only executing if existing requests are in the
          // delay queue
          resolve()
        }).catch(error => {
          that.showError(that.extractErrorMessage(error))
          reject(error)
        }).finally(() => {
          that.saving = false
        })
      })
    },
    validatePlaybook (rootContainer) {
      this.isPlaybookInvalid = !!this.validatePlaybookItems(rootContainer.children)
      return this.isPlaybookInvalid
    },
    orderWasChanged (delayTime = 3000) {
      return this.handleOrderChanged(this.rootContainer, this.rootContainer.children, delayTime).then(() =>
        this.updateLastSaved()
      ).catch(error => {
        this.showError(this.extractErrorMessage(error))
      })
    },
    linkLibraryItemToPlaybook (selectedLibraryItem) {
      const index = this.playbookItems.length
      this.addItemToPlaybookList({ item: selectedLibraryItem, index })

      this.linkPlaybookItem(selectedLibraryItem, this.rootContainer, index)
        .catch(error => {
          console.error(error)
          this.removeItemFromPlaybookList({ index })
        })
    }
  }
}
</script>

<style scoped lang="scss">

.scrollspyClass {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px 0 0;
}

i:hover {
  cursor: move;
}

textarea {
  padding: 18px 16px;
  font-size: 16px;
  background-color: $white;
  border: none;
  border-radius: 12px;
}

.agenda-menu {
  width: 20%;
  transition: 0.3s ease-in;
  &.closedAgenda {
    width: 0;
    visibility: hidden;
  }
}

.sticky-alert {
  position: sticky;
  top: 0;
  z-index: 100;
}

.page-header {
  padding-left: 8px;
}

.agendaToggleBtn {
  position: relative;
  margin-top: -38px;
  left: -38px;
}

.agendaBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $slate;
  background-color: $white;
  box-shadow: 6px 8px 20px 5px rgba(179, 173, 159, 0.12);
  border-radius: 20px;
  opacity: 0.4;
  &:hover {
    opacity: 0.6;
  }
}

.list-container {
  padding-left: 35px !important;
}

.playbook-container {
  height: calc(100vh - 256px);
}

:deep(.modal-body--custom) {
  background: $background;
}

.playbook-options {
  min-width: 220px;
}

.settings-container {
  display: block;
  padding: 32px 0;

  &__audio-input-selector, &__user-input-selector {
    width: 408px;
  }
}

</style>

<template>
  <div class="d-flex">
    <b-btn :id="'insert-item-btn-' + currentItemIndex"
           class="insert-item-btn"
           @click="$emit('insert')"
    >
      <i class="fas fa-xs fa-plus-circle"></i>
      <hr class="line-divider">
    </b-btn>
    <b-tooltip :target="'#insert-item-btn-' + currentItemIndex"
               triggers="hover"
               placement="left"
               boundary="viewport"
    >
      {{staticText.addNewItemBtn}}
    </b-tooltip>
  </div>
</template>

<script>

export default {
  name: "PlaybookItemInsertButton",
  props: {
    currentItemIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        addNewItemBtn: "Add New Item"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

.insert-item-btn {
  display: flex;
  width: 100%;
  padding: 0;
  background: none;
  cursor: pointer;
}

.insert-item-btn:hover > hr.line-divider {
  visibility: visible;
  border-color: $brown80;
}

.line-divider {
  visibility: hidden;
  margin-top: auto;
  margin-bottom: auto;
  width: 95%;
}

</style>

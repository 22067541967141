<template>
  <b-modal id="duplicate-modal-202401231112"
           v-model="showModal"
           centered
           no-close-on-esc
           no-close-on-backdrop
           footer-class="justify-content-end"
           :ok-title="staticText.saveAndProceedBtn"
           :ok-disabled="!!playbookNameFeedback || duplicating"
           :cancel-title="staticText.cancelBtn"
           @shown="focusInputField"
           @close="closeModal"
           @cancel="closeModal"
           @ok.prevent="handleClonePlaybook"
  >
    <template #modal-header-close>
      <img src="@/assets/svgs/close-icon-white-x.svg" class="pb-1"/>
    </template>
    <template #modal-title>
      <img src="@/assets/svgs/duplicate-icon.svg"
           class="mr-2"
      />
      {{ staticText.duplicateModalTitle }}
    </template>
    <div class="container">
      <progress-indicator v-if="duplicating"></progress-indicator>
      <div v-else>
        <!--  Cloning Errors    -->
        <base-alert
          v-if="error"
          :dismissible="true"
          :alert-message="error"
          variant="danger"
        ></base-alert>

        <!--    name input    -->
        <div class="col input-wrapper">
          <BaseFormInput id="playbook-name-202101231113"
                         ref="playbook-name-202101231113"
                         v-model="newPlaybookName"
                         :state-function="validatePlaybookName"
                         :invalid-feedback="playbookNameFeedback"
                         :placeholder-text="staticText.enterPlaybookName"
                         class="mt-3 mb-2"
          />
        </div>

        <!--    Team/Client Selector    -->
        <div v-if="isGlobalAdmin">
          <treeselect id="tenantSelection"
                      class="mb-3"
                      v-model="selectedTenant"
                      :options="currentUser.permissions.tenants"
                      :normalizer="normalizer"
                      :placeholder="staticText.teamSelectionPlaceholder"
                      value-format="Object"
                      search-nested
          ></treeselect>
        </div>
        <div v-else>
          <div v-if="teamsForUser && teamsForUser.length > 1">
            <BaoVueMultiSelect
              v-model="selectedTenant"
              :options="teamsForUser"
              :placeholder="staticText.teamSelectionPlaceholder"
              :show-labels="false"
              label="name"
              track-by="schema_name"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BaseFormInput from "@/apps/base/BaseFormInput"
import appInfo from "@/apps/talkscript"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import BaseAlert from "@/apps/base/BaseAlert"
import Treeselect from "@riophae/vue-treeselect"
import { treeSelectUtils } from "@/apps/user/TeamManagementComponents/TeamManagementUtils"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { getDuplicatedItemTitle } from "../utils"

export default {
  name: "PlaybookDuplicateModal",
  components: {
    BaseFormInput,
    ProgressIndicator,
    BaseAlert,
    Treeselect,
    BaoVueMultiSelect
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    playbook: {
      type: Object,
      required: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    isPlaybookInvalid: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedTenant: null,
      error: null,
      showModal: false,
      playbookNameFeedback: "",
      duplicating: false,
      newPlaybookName: null,
      staticTextDefault: {
        cancelBtn: "Cancel",
        enterPlaybookName: "Enter a name for the new playbook",
        duplicateModalTitle: "Duplicate Playbook",
        saveAndProceedBtn: "Save And Proceed",
        emptyPlaybookNameError: "Playbook name cannot be empty",
        playbookNameMaxCharacterLimitMessage: "The playbook name cannot exceed 120 characters.",
        cloneError: "Could not duplicate playbook due to error: ",
        teamSelectionPlaceholder: "Duplicate to a different team (optional)",
        duplicateToTeamSuccessTitle: "Playbook duplicated successfully",
        duplicateToTeamSuccessDescription: "Your playbook has been duplicated to your selected team. Please switch team to see it."
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.showModal = val
        if (val) {
          this.newPlaybookName = getDuplicatedItemTitle(this.playbook.name)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      isGlobalAdmin: "auth/isGlobalAdmin"
    }),
    teamsForUser () {
      return this.currentUser.permissions.teams_for_user
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...treeSelectUtils.methods,
    ...playbookServices.methods,
    ...mapActions({
      fetchPlaybook: "playbookConfiguratorStore/fetchPlaybook"
    }),
    closeModal () {
      this.newPlaybookName = null
      this.$emit("close")
    },
    validatePlaybookName () {
      this.error = null
      this.duplicating = false
      if (this.newPlaybookName === null) {
        this.playbookNameFeedback = "disabled"
        return null
      }
      if (this.newPlaybookName === "") {
        this.playbookNameFeedback = this.staticText.emptyPlaybookNameError
      } else if (this.newPlaybookName.length > 120) {
        this.playbookNameFeedback = this.staticText.playbookNameMaxCharacterLimitMessage
      } else this.playbookNameFeedback = ""
      return this.playbookNameFeedback === ""
    },
    getCurrentSchemaName () {
      for (const tenant of this.currentUser.permissions.tenants) {
        const selectedTenant = this.searchTreeForSelectedTenant(tenant)
        if (selectedTenant) {
          return selectedTenant.schema_name
        }
      }
    },
    searchTreeForSelectedTenant (tenant) {
      if (tenant.selected) {
        return tenant
      } else if (tenant.children.length > 0) {
        for (const child of tenant.children) {
          const result = this.searchTreeForSelectedTenant(child)
          if (result) return result
        }
        return null
      }
      return null
    },
    async handleClonePlaybook () {
      this.duplicating = true

      if (this.editMode) {
        await this.$emit("validatePlaybook")

        if (this.isPlaybookInvalid) {
          this.duplicating = false
          return
        }
      }

      try {
        const targetSchema = this.selectedTenant ? this.selectedTenant.schema_name : null
        const newPlaybookId = await this.cloneTemplate(this.playbook.id, this.newPlaybookName, targetSchema)
        const currentSchema = this.getCurrentSchemaName()
        if (targetSchema && (targetSchema !== currentSchema)) {
          const successMessage = {
            title: this.staticText.duplicateToTeamSuccessTitle,
            description: this.staticText.duplicateToTeamSuccessDescription
          }
          this.showGlobalToast({
            status: "success",
            message: successMessage
          })
        } else {
          await this.fetchPlaybook(newPlaybookId)
          await this.$router.push(appInfo.route.path + "/edit/" + newPlaybookId)
        }
      } catch (err) {
        this.error = this.staticText.cloneError + this.extractErrorMessage(err)
      } finally {
        this.duplicating = false
        this.closeModal()
      }
    },
    focusInputField () {
      this.$refs["playbook-name-202101231113"].focus()
    }
  }
}

</script>

<style scoped>

:deep(.multiselect__input) {
  max-width: unset !important;
}

</style>

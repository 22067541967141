import store from "@/store"

export const getDuplicatedItemTitle = (title) => {
  const userLanguage = store.getters["auth/user"].language
  const copyLabel = userLanguage === "de" ? "Kopie von" : "Copy of"

  if (title.toLowerCase().startsWith(copyLabel.toLowerCase())) {
    // This checks if last part of title is a number after a space e.g. Test 124, but not Test123
    const numberMatch = title.match(/\s(\d+)$/)
    if (numberMatch) {
      return title.replace(/\s(\d+)$/, (match, matchingDigit) => {
        return ` ${parseInt(matchingDigit, 10) + 1}`
      })
    } else {
      return `${title} 1`
    }
  } else {
    const newName = `${copyLabel} ${title}`
    return newName
  }
}

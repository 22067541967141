<template>
  <div class="draggable-multiselect">
    <!-- Draggable section   -->
    <div v-if="showDraggableSelection && visibleValues.length > 0"
         class="multiselect draggable"
    >
      <div class="multiselect__tags multiselect__tags__draggable">
        <slot
          name="draggableSelection"
          :search="search"
          :remove="removeElement"
          :values="visibleValues"
          :is-open="isOpen"
        >
          <div class="multiselect__tags-wrap">
            <draggable
              v-model="reOrderedValues"
              handle=".cursor-pointer"
              @start="drag = true"
              @end="drag = false"
              @change="$emit('order-changed', reOrderedValues)"
            >
              <div v-for="(option, index) of reOrderedValues" :key="index">
                <slot name="tag" :option="option" :search="search" :remove="removeElement">
                  <span class="multiselect__tag cursor-pointer" :key="index">
                    <span v-text="getOptionLabel(option)"></span>
                    <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="removeElement(option)"
                       @mousedown.prevent="removeElement(option)" class="multiselect__tag-icon"></i>
                  </span>

                </slot>
              </div>
            </draggable>

          </div>
          <template v-if="internalValue && internalValue.length > limit">
            <slot name="limit">
              <strong class="multiselect__strong" v-text="limitText(internalValue.length - limit)"/>
            </slot>
          </template>
        </slot>
      </div>
    </div>
    <!-- End of Draggable section   -->
    <div
      :tabindex="searchable ? -1 : tabindex"
      :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
      @focus="activate()"
      @blur="searchable ? false : deactivate()"
      @keydown.self.down.prevent="pointerForward()"
      @keydown.self.up.prevent="pointerBackward()"
      @keypress.enter.tab.stop.self="addPointerElement($event)"
      @keyup.esc="deactivate()"
      class="multiselect">
      <slot name="caret" :toggle="toggle">
        <div @mousedown.prevent.stop="toggle()" class="multiselect__select"></div>
      </slot>
      <slot name="clear" :search="search"></slot>
      <div ref="tags" class="multiselect__tags"
           :class="{'multiselect__tags__draggable-input': showDraggableSelection && visibleValues.length > 0}">
        <slot
          v-if="!showDraggableSelection"
          name="selection"
          :search="search"
          :remove="removeElement"
          :values="visibleValues"
          :is-open="isOpen"
        >
          <div class="multiselect__tags-wrap" v-show="visibleValues.length > 0">
            <template v-for="(option, index) of visibleValues" @mousedown.prevent>
              <slot name="tag" :option="option" :search="search" :remove="removeElement">
                <span class="multiselect__tag" :key="index">
                  <span v-text="getOptionLabel(option)"></span>
                  <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="removeElement(option)"
                     @mousedown.prevent="removeElement(option)" class="multiselect__tag-icon"></i>
                </span>
              </slot>
            </template>
          </div>
          <template v-if="internalValue && internalValue.length > limit">
            <slot name="limit">
              <strong class="multiselect__strong" v-text="limitText(internalValue.length - limit)"/>
            </slot>
          </template>
        </slot>
        <transition name="multiselect__loading">
          <slot name="loading">
            <div v-show="loading" class="multiselect__spinner"/>
          </slot>
        </transition>
        <input
          ref="search"
          v-if="searchable"
          :name="name"
          :id="id"
          type="text"
          autocomplete="nope"
          :placeholder="placeholder"
          :style="inputStyle"
          :value="search"
          :disabled="disabled"
          :tabindex="tabindex"
          @input="updateSearch($event.target.value)"
          @focus.prevent="activate()"
          @blur.prevent="deactivate()"
          @keyup.esc="deactivate()"
          @keydown.down.prevent="pointerForward()"
          @keydown.up.prevent="pointerBackward()"
          @keypress.enter.prevent.stop.self="addPointerElement($event)"
          @keydown.delete.stop="removeLastElement()"
          class="multiselect__input"
        />
        <span
          v-if="isSingleLabelVisible"
          class="multiselect__single"
          @mousedown.prevent="toggle"
        >
          <slot name="singleLabel" :option="singleValue">
            <template>{{ currentOptionLabel }}</template>
          </slot>
        </span>
        <span
          v-if="placeholderVisible"
          class="multiselect__placeholder"
          @mousedown.prevent="toggle"
        >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </span>
      </div>
      <transition name="multiselect">
        <div
          class="multiselect__content-wrapper"
          v-show="isOpen"
          @focus="activate"
          tabindex="-1"
          @mousedown.prevent
          :style="{ maxHeight: optimizedHeight + 'px' }"
          ref="list"
        >
          <ul class="multiselect__content" :style="contentStyle">
            <slot name="beforeList"></slot>
            <li v-if="multiple && max === internalValue.length">
              <span class="multiselect__option">
                <slot name="maxElements">Maximum of {{ max }} options selected. First remove a selected option to select another.</slot>
              </span>
            </li>
            <template v-if="!max || internalValue.length < max">
              <li class="multiselect__element" v-for="(option, index) of filteredOptions" :key="index">
                <span
                  v-if="!(option && (option.$isLabel || option.$isDisabled))"
                  :class="optionHighlight(index, option)"
                  @click.stop="select(option)"
                  @mouseenter.self="pointerSet(index)"
                  :data-select="option && option.isTag ? tagPlaceholder : selectLabelText"
                  :data-selected="selectedLabelText"
                  :data-deselect="deselectLabelText"
                  class="multiselect__option">
                  <slot name="option" :option="option" :search="search">
                    <span>{{ getOptionLabel(option) }}</span>
                  </slot>
                </span>
                <span
                  v-if="option && (option.$isLabel || option.$isDisabled)"
                  :data-select="groupSelect && selectGroupLabelText"
                  :data-deselect="groupSelect && deselectGroupLabelText"
                  :class="groupHighlight(index, option)"
                  @mouseenter.self="groupSelect && pointerSet(index)"
                  @mousedown.prevent="selectGroup(option)"
                  class="multiselect__option">
                  <slot name="option" :option="option" :search="search">
                    <span>{{ getOptionLabel(option) }}</span>
                  </slot>
                </span>
              </li>
            </template>
            <li v-show="showNoResults && (filteredOptions.length === 0 && search && !loading)">
              <span class="multiselect__option">
                <slot name="noResult" :search="search">No elements found. Consider changing the search query.</slot>
              </span>
            </li>
            <li v-show="showNoOptions && (options.length === 0 && !search && !loading)">
              <span class="multiselect__option">
                <slot name="noOptions">List is empty.</slot>
              </span>
            </li>
            <slot name="afterList"></slot>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import draggable from "vuedraggable"

export default {
  /**
   * A custom implementation of the VueMultiselect component from the "vue-multiselect" package,
   * with added functionality and behavior modifications.
   *
   * Key Features:
   * - Selected options are draggable by default when the "multiple" prop is set to `true`.
   *   - This behavior can be disabled by setting the "isDraggable" prop to `false`.
   * - Emits an additional `order-changed` event with the reordered values as the payload.
   * - Customized CSS properties tailored to application requirements.
   * - Similar to default 'selection' slot, an additional slot with name 'draggableSelection' is added for more
   *   flexibility with following scopes.
   *    - search – the search value
   *    - removeElement – method to remove the slot (pass the option)
   *    - values – Array of selected values
   *    - is-open – Boolean if the select is open
   *
   * Notes:
   * - All default props, slots, events, and functionalities follow the official VueMultiselect documentation.
   * - Placeholder text is always visible when options are draggable; otherwise, it follows the official behavior
   *   and can be controlled via props as specified in the documentation.
   *
   * For more details, refer to the official documentation:
   * https://vue-multiselect.js.org/#sub-getting-started
   */

  name: "MultiselectDraggable",
  mixins: [VueMultiselect],
  components: { draggable },
  props: {
    isDraggable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      drag: false,
      reOrderedValues: []
    }
  },
  watch: {
    visibleValues: {
      immediate: true,
      handler (val) {
        this.reOrderedValues = val
      }
    }
  },

  computed: {
    showDraggableSelection () {
      return this.isDraggable && !this.isSingleLabelVisible
    },
    placeholderVisible () {
      return this.isPlaceholderVisible || this.showDraggableSelection
    }
  }
}

</script>

<style scoped lang="scss">
.draggable-multiselect {
  .multiselect {
    // Mixin for text wrapping
    .break-word-no-whit-space {
      white-space: normal !important;
      word-break: break-all !important;
    }

    .multiselect__single,
    .multiselect__option,
    .multiselect__tag span {
      @extend .break-word-no-whit-space;
    }

    .multiselect__single {
      padding-top: 6px !important;
      padding-left: 6px !important;
      margin-top: 4px !important;
      overflow-wrap: anywhere;
    }

    .multiselect__tags {
      padding: 8px 35px 8px 8px;
      min-height: 56px !important;
      font-size: 16px !important;
      border: none !important;
      border-radius: 12px !important;
      box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12) !important;

      &__draggable {
        padding: 12px 8px 4px 8px !important;
        border-radius: 12px 12px 0 0 !important;
      }

      &__draggable-input {
        border-radius: 0 0 12px 12px !important;
        padding: 4px 35px 4px 8px;
      }
    }

    .multiselect__tag {
      background: unset;
      color: unset;
      display: flex;

      .multiselect__tag-icon {
        content: url("../../assets/svgs/close-circle.svg");
        width: 16px;

        &:hover,
        &::after {
          color: unset;
          background: unset;
        }
      }
    }

    .multiselect__option--highlight::after {
      background: unset;
      color: unset;
    }

    .multiselect__input {
      margin: 10px 0 6px 4px !important;
    }

    .multiselect__placeholder {
      padding-left: 8px !important;
      padding-top: 8px !important;
      font-size: 16px;
    }

    .multiselect__select {
      background: transparent !important;
      height: auto !important;
      top: 12px !important;
      padding: 2px 8px !important;

      &:before {
        content: url("../../../public/img/icons/arrow-icon.svg") !important;
        border: none !important;
        top: 0 !important;
      }
    }

    .multiselect__option--highlight {
      color: $slate !important;
      background-color: $slate04 !important;
      border-radius: 12px !important;
    }

    .multiselect__content-wrapper {
      margin-top: 6px;
      background-color: $white !important;
      border-radius: 14px !important;
      overflow-x: hidden;
    }
  }
}

</style>

<template>
  <div class="h-100 scrollbar-y nice-scrollbar">
    <!-- Navigation bar -->
    <b-list-group class="navigation-bar-list-group">
      <draggable v-model="rootContainer.children"
                 v-bind="dragOptions"
                 group="navBar"
                 :disabled="disabled"
                 @start="drag = true"
                 @end="drag = false"
                 @change="orderWasChanged"
      >
        <transition-group :name="!drag ? 'flip-list' : null"
                          type="transition"
                          tag="div"
        >
          <div v-for="(playbookItem, index) in rootContainer.children"
               :key="`playbookItem-${playbookItem.id}-${index}`"
               :href="'#' +playbookItem.uniqueId"
               class="cursor-pointer mb-2 mr-1"
          >
            <b-list-group-item
              :href="'#' +playbookItem.uniqueId"
              :class="{ active: itemIsActive(playbookItem) }"
              @click="setActiveItem(playbookItem)"
            >
              <div class="text-left text-truncate"
                   :id="`playbookItem-${playbookItem.id}`"
              >
                {{ index + 1 }}.

                <span :id="'nav-asterisks-202102192040-'+index"
                      v-if="playbookItem.required"
                >
                  *
                </span>

                {{ playbookItem.name }}
              </div>
              <div v-if="isTextTruncated(`playbookItem-${playbookItem.id}`)">
                <b-tooltip :target="`playbookItem-${playbookItem.id}`"
                           triggers="hover"
                           placement="top"
                           boundary="viewport"
                >
                  {{ playbookItem.name }}
                </b-tooltip>
              </div>
            </b-list-group-item>
          </div>
        </transition-group>
      </draggable>
    </b-list-group>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import { mapGetters, mapMutations } from "vuex"
import { isTextTruncated } from "./utils"

export default {
  name: "playbookItemsNavigation",
  components: {
    draggable
  },
  data () {
    return {
      drag: false
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      rootContainer: "playbookConfiguratorStore/getRootContainer",
      activeItem: "playbookConfiguratorStore/getActiveItem"
    }),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    ...mapMutations({
      setActiveItem: "playbookConfiguratorStore/setActiveItem"
    }),
    isTextTruncated,
    ...playbookServices.methods,
    orderWasChanged () {
      this.handleOrderChanged(this.rootContainer, this.rootContainer.children, 0)
    },
    itemIsActive (item) {
      return this.activeItem && this.activeItem.id && this.activeItem.id === item.id
    }
  }
}
</script>

<style lang="scss" scoped>

.navigation-bar-list-group {
  .list-group-item {
    border: none;
    background: inherit;
    padding: 10px;
    transition: 0.3s ease-in;
    &:hover {
      color: $brown;
      background-color: $brownlight;
      border-radius: 12px;
    }
    &.active {
      color: $white;
      background-color: $brown;
      border-radius: 12px;
    }
  }
}

</style>

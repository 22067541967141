<template>
  <div>
    <draggable
      v-model="answerChoices"
      v-bind="dragOptions"
      handle=".cursor-pointer"
      :group="'answer-choices-'+item.id"
      :disabled="!canModify"
      @start="drag = true"
      @end="drag = false"
      @change="handleOrderChanged"
    >
      <transition-group :name="!drag ? 'flip-list' : null"
                        tag="div"
                        type="transition"
      >
        <div :id="'answer-'+ item.id + '-' + index"
             v-for="(answerChoice, index) in answerChoices"
             :key="'Answer-' + item.id + '-' +  index"
             :class="{'cursor-pointer': isDraggable && canModify && !isAnswerProtected(answerChoice)}"
        >
          <!--   Answer container       -->
          <!--   Answer contents/controls       -->
          <div :id="'answer-choice-btn-' + answerChoice.id"
               :class="[ 'answer-choice-wrapper', 'py-1', {'no-border': !canModify && index === answerChoices.length - 1} ]"
          >

            <div class="d-flex align-items-center ac-left-section">
              <div class="d-flex align-items-center">
                <bao-toggle-button
                  class="mr-2"
                  :is-active="false"
                  :type="getIconClass"
                ></bao-toggle-button>
                <bao-editable-text
                  :ref="'answer-choice-btn-' + answerChoice.id"
                  v-model="answerChoice.label"
                  :disabled="!canModify"
                  :extra-style="editableTextStyle"
                  :clickableTextExtraStyle="editableTextStyle"
                  @input-changed="newLabel => deactivateRenaming(answerChoice, newLabel, index)"
                ></bao-editable-text>
              </div>
              <!--   Answer tags       -->
              <div
                v-if="canModify && hasItemTypeTag"
                class="ml-2 d-flex align-items-center"
              >
                <base-tag-input
                  class="playbook"
                  :labels="answerChoice.tags"
                  :available-tags="answerTagOptions"
                  :item-identifier="index"
                  :answer-choice-tags="true"
                  :item-type-tag="getItemTypeTag(item.tags)"
                  :system-protected-tags="true"
                  @update-answer-tag="(newTag, tagIndex) => updateAnswerTag(newTag, tagIndex, index)"
                ></base-tag-input>
                <div v-if="answerChoice.error" class="ml-2 text-red">
                  {{ answerChoice.error }}
                </div>
              </div>
            </div>

            <!-- Action icon list, Action Configurator and Delete buttons -->
            <div v-if="answerToRename !== answerChoice.id"
                 class="d-flex my-auto"
            >
              <!-- Action icon list -->
              <answer-actions-display
                :id="'answer-actions-display-202110141612-'+answerChoice.id"
                v-if="answerChoice.actions && answerChoice.actions.length>0"
                v-model="answerChoice.actions"
                :parent-container="rootContainer"
                :answer-choice-id="answerChoice.id"
                class="my-auto"
              ></answer-actions-display>

              <!-- Add action button-->
              <b-btn
                :id="'add-action' + answerChoice.id"
                v-if="!item.is_library_item"
                class="options-btn mx-1"
                @click.stop="!openActionsConfigurator(answerChoice)"
              >
                <img src="@/assets/svgs/three-dot-btn.svg">
              </b-btn>

              <!-- Delete button -->
              <bao-delete-button
                v-if="canModify && !isAnswerProtected(answerChoice)"
                :id="'answer-choice-remove-btn-202103180146-' + answerChoice.id"
                :tooltip="staticText.removeAnswerBtn"
                class="my-auto delete-btn"
                icon="fa fa-xs fa-trash global-color-slate-80 p-2"
                extra-class="p-0 m-0 bg-transparent"
                @delete="deleteAnswerChoice(index)"
              ></bao-delete-button>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <!-- Add New Answer Button -->
    <div class="d-flex align-items-center mt-1"
         v-if="canModify"
    >
      <bao-toggle-button
        class="mr-2"
        :is-active="false"
        :type="getIconClass"
      ></bao-toggle-button>

      <div
        type="button"
        id="add-answer-choice-btn-202101241251"
        class="add-new-answer-btn"
        @click="createNewAnswerChoice"
      >
        {{ staticText.addNewAnswerBtn }}
      </div>
    </div>

    <!-- Action modal -->
    <b-modal v-if="answerToAddAction"
             size="xl"
             v-model="showActionsConfigurator"
             :title="staticText.actionConfiguratorTitle"
             hide-footer
    >
      <answer-actions-configurator
        v-model="answerToAddAction.actions"
        :answer-choice-id="answerToAddAction.id"
        :playbook-item-id="item.id"
        @close="closeActionsConfigurator"
        @input="handleAnswerChoicesChanged"
      ></answer-actions-configurator>
    </b-modal>
  </div>
</template>

<script>
import _isEqual from "lodash/isEqual"
import draggable from "vuedraggable"
import { tooltipDelay } from "@/config"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import axios from "axios"
import AnswerActionsConfigurator from "@/apps/talkscript/components/AnswerActionsConfigurator"
import AnswerActionsDisplay from "@/apps/talkscript/components/AnswerActionsDisplay"
import { v4 as uuidv4 } from "uuid"
import BaseTagInput from "@/apps/base/BaseTagInput"
import { mapActions, mapGetters } from "vuex"
import BaoEditableText from "@/apps/base/BaoEditableText"
import BaoToggleButton from "@/apps/base/BaoToggleButton"

export default {
  name: "AnswersConfigurator",
  components: {
    BaoDeleteButton,
    draggable,
    AnswerActionsConfigurator,
    AnswerActionsDisplay,
    BaseTagInput,
    BaoEditableText,
    BaoToggleButton
  },
  props: {
    item: { // Playbook item
      type: Object,
      required: true
    },
    canModify: { // checks if playbook item can be modified
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      axios,
      tooltipDelay,
      uuidv4,
      isDraggable: true,
      drag: false,
      answerChoices: [],
      answerToRename: null,
      showActionsConfigurator: false,
      shouldBeTransformed: false,
      disableDraggable: false,
      answerToAddAction: null,
      editClasses: "container d-flex justify-content-between bao-drag-handle btn btn-light mb-2",
      answerTagOptions: [],
      itemTypeTag: null,
      iconsMapping: {
        single_select_radio: "radio",
        single_select_dropdown: "radio",
        question: "checkbox",
        rated_multiselect: "checkbox",
        multi_select_dropdown: "checkbox"
      },
      staticTextDefault: {
        renameAnswerTooltip: "Click to rename answer",
        addAnswerPlaceholder: "Add answer text",
        removeAnswerBtn: "Remove answer option",
        addNewAnswerBtn: "Add New Answer",
        actionConfiguratorTitle: "Answer Actions"
      }
    }
  },
  computed: {
    ...mapGetters({
      nonSystemLabels: "labels/getAllNonSystemLabels",
      rootContainer: "playbookConfiguratorStore/getRootContainer"
    }),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    hasItemTypeTag () {
      return !!this.itemTypeTag
    },
    getIconClass () {
      return this.iconsMapping[this.item.item_type]
    },
    editableTextStyle () {
      return {
        "max-width": "unset",
        "white-space": "normal",
        "word-break": "break-all"
      }
    }
  },
  watch: {
    "item.answer_choices" (newVal, oldVal) {
      // The watch on item is required because on change of item order, this component must be updated to the new item's
      // answers
      if (!(_isEqual(newVal, oldVal))) {
        this.transformAnswerActions()
        this.sortAnswerTags()
      }
    }
  },
  async mounted () {
    await this.transformAnswerActions()
    await this.sortAnswerTags()
  },
  methods: {
    ...mapActions({
      fetchAllNonSystemLabels: "labels/fetchAllNonSystemLabels"
    }),
    async sortAnswerTags () {
      this.itemTypeTag = this.getItemTypeTag(this.item.tags)
      await this.fetchAllNonSystemLabels()
      this.answerTagOptions = this.nonSystemLabels.filter(label => label.name.includes(`${this.itemTypeTag}/`))
    },
    async editAnswerChoiceLabels (answerChoice, newLabels, removedLabel = null) {
      try {
        this.answerChoices.find(item => item.id === answerChoice.id).tags = newLabels
        // save labels
      } catch (error) {
      }
    },
    async transformAnswerActions () {
      let answerChoices = JSON.stringify(this.item.answer_choices)
      answerChoices = JSON.parse(answerChoices)
      for (const index in answerChoices) {
        const answerActions = answerChoices[index].actions || []
        for await (const answerAction of answerActions) {
          // The answer actions have the ID of the additional data choice, but the options are the playbooks which have playbook Id
          // So I had to introduce objectId which will match talkscriptId for add_playbook options, and data.id for other options
          if (answerAction.type === "add_playbook") {
            if (!answerAction.objectId) {
              const { data } = await axios.get(`/api/objectionchoices/${answerAction.data.id}`)
              answerAction.objectId = data.talkscript
            }
          } else {
            answerAction.objectId = answerAction.data.id
          }
        }
      }
      this.answerChoices = answerChoices
    },
    deleteAnswerChoice (index) {
      this.answerChoices.splice(index, 1)
      this.handleAnswerChoicesChanged()
    },
    createNewAnswerChoice () {
      const answerChoice = {
        id: uuidv4(),
        label: "",
        // The below line sets importance to true for the new answer choice if any of the existing answer choices
        // have their importance set to true
        with_importance: this.answerChoices ? this.answerChoices.some(item => item.with_importance) : null,
        actions: [],
        tags: [],
        order: this.answerChoices.length + 1
      }
      this.answerChoices = this.answerChoices ? [...this.answerChoices, answerChoice] : [answerChoice]
      this.clickAnswerBtn(answerChoice.id)
    },
    clickAnswerBtn (answerId) {
      // we have to wait for the new answerChoice to be rendered first.
      this.$nextTick(() => {
        const btnRef = "answer-choice-btn-" + answerId
        this.$refs[btnRef][0].$refs["clickable-text-display"].click()
      })
    },
    deactivateRenaming (answerChoice, newLabel, index) {
      if (answerChoice.label === "" && newLabel === "") {
        this.deleteAnswerChoice(index)
        return
      }
      this.$set(answerChoice, "label", newLabel)
      this.isDraggable = true
      if (this.hasItemTypeTag && answerChoice.tags.length === 0) {
        this.$set(answerChoice, "error", "Tag is required")
        return
      }
      this.handleAnswerChoicesChanged()
    },
    handleOrderChanged () {
      this.answerChoices.map((answerChoice, index) => {
        answerChoice.order = index + 1
        return answerChoice
      })
      this.handleAnswerChoicesChanged()
    },
    handleAnswerChoicesChanged () {
      this.$emit("answer-choices-changed", this.answerChoices)
    },
    openActionsConfigurator (answerChoice) {
      this.answerToAddAction = answerChoice
      this.showActionsConfigurator = true
    },
    closeActionsConfigurator () {
      this.showActionsConfigurator = false
      this.answerToAddAction = null
    },
    isAnswerProtected (answerChoice) {
      if (Array.isArray(answerChoice.tags) && answerChoice.tags.length) {
        return answerChoice.tags.includes("system/protected")
      }
      return false
    },
    getItemTypeTag (labels) {
      return labels.filter(label => this.getTagParent(label) === "item_type")[0]
    },
    getTagParent (label) {
      const splittedLabel = label.split("/")
      return splittedLabel[0]
    },
    updateAnswerTag (newTag, tagIndex, answerChoiceIndex) {
      const answerChoice = this.answerChoices[answerChoiceIndex]
      delete answerChoice.error
      answerChoice.tags.splice(tagIndex, 1, newTag)
      // This is to check if any answer choice is being renamed, and to confirm changes after selecting tag
      if (this.answerToRename) {
        const currentAnswerChoicesIndex = this.answerChoices.length - 1
        this.deactivateRenaming(this.answerChoices[currentAnswerChoicesIndex], currentAnswerChoicesIndex)
      }
      this.handleAnswerChoicesChanged()
    }
  }
}
</script>

<style scoped lang="scss">
i:hover {
  cursor: move;
}

.border-white {
  border-color: $white;
}

.add-new-answer-btn {
  opacity: 0.7;
  display: inline-block;
  padding: 2px 8px;
  transition: 0.3s background-color ease-in;
  color: $slate80;
  border-radius: 6px;

  &:hover {
    background-color: $slate06;
  }
}

.options-btn,
.delete-btn {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  transition: 0.3s background-color ease-in;
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: $slate08;
  }

  .delete-btn {
    padding: 11px 12px;
  }
}

.answer-choice-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $slate06;

  .no-border {
    border: none;
  }
}

.ac-left-section {
  max-width: calc(100% - 106px); // 106px is the max estimated with of the right section
  width: fit-content;
}

</style>

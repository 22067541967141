<template>
  <div>
    <!-- Action Icon Display -->
    <div :id="'answer-actions-icons-display-202103211433-'+answerChoiceId+uniqueId+level"
         class="d-flex align-items-center"
    >
      <div :id="'action-' + index + answerChoiceId"
           v-for="(action, index) in answerActions"
           :key="index"
           class="mr-1 action-icon small"
           @click.stop
      >
        <i :class="getActionIcon(action.type)"></i>
      </div>
    </div>

    <!-- Non Configurable answerActions PopoverView-->
    <b-popover :id="'answer-actions-display-202103211433-'+answerChoiceId+uniqueId+level"
               :title="staticText.actionPopoverTitle"
               :target="'answer-actions-icons-display-202103211433-'+answerChoiceId+uniqueId+level"
               triggers="hover"
               placement="top"
    >
      <div class="popover-wrapper">
        <div v-for="(action, index) in answerActions"
             :key="index"
             class="d-flex content-wrapper mb-1 shadow-sm mh-40px"
        >
          <div class="d-flex w-55">
            <span class="my-auto mx-2">
              {{ index + 1 }}
            </span>

            <div class="d-flex justify-content-left my-auto">
              <i v-if="!!getActionIcon(action.type)"
                 :class="getActionIcon(action.type)+' mr-2 my-auto'"
              />
              <div>
                {{ getActionLabel(action.type) }}
              </div>
            </div>
          </div>

          <div class="align-self-center w-45">
            <div v-if="!!action.type && !!action.data && !!action.data.id">
              {{ getObjectLabel(action.objectId, action.type) }}
            </div>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { actionTypes, capitalizeFirstLetter } from "./utils"

export default {
  name: "AnswerActionsDisplay",
  props: {
    level: {
      type: Number,
      default: 0,
      required: false
    },
    uniqueId: {
      type: String,
      required: false,
      default: "defaultUniqueId"
    },
    value: { // actions for an answer
      type: Array,
      required: true
    },
    answerChoiceId: {
      type: String,
      required: true
    },
    parentContainer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      answerActions: [],
      actionTypes,
      staticTextDefault: {
        actionPopoverTitle: "Answer Actions",
        itemNotFound: "Item not found",
        jump: "Jump to",
        collapse: "Collapse",
        expand: "Expand",
        objection: "Objection",
        add_playbook: "Add Playbook",
        set_answer: "Set Answer"
      }
    }
  },
  computed: {
    ...mapGetters({
      allPlaybooks: "playbook/getAllPlaybooks"
    }),
    playbookItems () {
      return this.parentContainer.children ? this.parentContainer.children.filter(item => !!item.id) : []
    },
    playbookObjections () {
      return this.parentContainer.objections ? this.parentContainer.objections.filter(item => !!item.id) : []
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    value (newValue) {
      this.answerActions = JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted () {
    this.answerActions = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    getActionIcon (actionType) {
      try {
        return this.actionTypes[actionType].icon
      } catch (error) {
        return null
      }
    },
    getActionLabel (actionType) {
      return this.staticText[actionType] || capitalizeFirstLetter(actionType)
    },
    getOptionsBasedOnActionType (actionType) {
      if (actionType === "objection") {
        return this.playbookObjections
      }
      if (actionType === "add_playbook") {
        return this.allPlaybooks
      }
      return this.playbookItems
    },
    getObjectLabel (id, actionType) {
      const objectsList = this.getOptionsBasedOnActionType(actionType)
      const index = objectsList.findIndex(item => item.id === id)
      if (index > -1) {
        const obj = objectsList[index]
        if (actionType === "add_playbook") {
          return `${obj.name} (ID: ${id})`
        }
        return `${index + 1} - ${obj.name}`
      }
      return this.staticText.itemNotFound
    }
  }
}
</script>

<style scoped lang="scss">

.content-wrapper {
  width: 100%;
  background-color: $white60;
  border-radius: 10px;
}

.mh-40px {
  min-height: 40px;
}

.popover-wrapper {
  background-color: $white40;
  border-radius: 20px;
  width: 250px;
}

.action-icon {
  display: flex;
  align-items: center;
  opacity: 0.7;
}

</style>

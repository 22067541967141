<template>
  <div class="confetti">
    <candy-confetti
      v-if="showConfetti"
    />
    <confetti-card
      :emoji="emoji"
      :header="heading"
      :subheading="subheading"
      @close-confetti="$emit('close-confetti')"
    />
  </div>
</template>

<script>
import ConfettiCard from "./ConfettiCard.vue"
import CandyConfetti from "./CandyConfetti.vue"

export default {
  name: "BaseConfetti",
  props: {
    showConfetti: {
      type: Boolean,
      default: true,
      required: false
    },
    emoji: {
      type: String,
      required: true,
      nullable: true
    },
    heading: {
      type: String,
      required: false,
      nullable: true
    },
    subheading: {
      type: String,
      required: true,
      nullable: true
    }
  },
  components: {
    ConfettiCard,
    CandyConfetti
  }
}
</script>

<style lang="scss" scoped>

.confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999999999999999;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $slate20;
}

</style>

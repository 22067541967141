<template>
  <div>
    <ProgressIndicator v-if="saving"></ProgressIndicator>
    <div v-else>
      <draggable
        v-model="answerActions"
        v-bind="dragOptions"
        :group="'answerActions'+answerChoiceId"
        handle=".cursor-pointer"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group :name="!drag ? 'flip-list' : null"
                          tag="div"
                          type="transition"
                          class="d-flex align-content-stretch flex-wrap"
        >
          <div v-for="(answerAction, index) in answerActions"
               :key="answerAction.frontendkey"
               :class="answerAction.error?'error-border':''"
               class="content-wrapper cursor-pointer w-100 my-2"
          >
            <div class="d-flex justify-content-between my-2">

              <div class="d-flex w-45 mr-1 my-auto">
                <span class="my-auto mx-1">
                  {{ index + 1 }}
                </span>

                <vue-multiselect
                  :id="'answerAction' + answerChoiceId + index"
                  v-model="answerAction.type"
                  :options="getAnswerActionTypes(index)"
                  :placeholder="staticText.selectAction"
                  :show-labels="false"
                  class="my-auto"
                  label="name"
                  track-by="name"
                  :max-height="330"
                  @select="handleActionTypeSelected(answerAction)"
                  @remove="handleActionTypeRemoved(index)"
                >
                  <template slot="option"
                            slot-scope="{option}"
                  >
                    <div class="d-flex justify-content-left">
                      <i v-if="!!getActionIcon(option.name)"
                         :class="getActionIcon(option.name)+' mr-2 my-auto'"
                      />

                      <div class="mr-2">
                        {{ getActionLabel(option.name) }}
                      </div>
                      <div class="text-red opacity-50">
                        {{ getActionCompatibilityLabel(option.name, option.$isDisabled) }}
                      </div>
                    </div>
                  </template>

                  <template slot="singleLabel"
                            slot-scope="{option}"
                  >
                    <div class="d-flex justify-content-left">
                      <i v-if="!!getActionIcon(option.name)"
                         :class="getActionIcon(option.name)+' mr-2 my-auto'"
                      />

                      <div class="mr-2">
                        {{ getActionLabel(option.name) }}
                      </div>
                      <div class="text-red">
                        {{ getActionCompatibilityLabel(option.name, option.$isDisabled) }}
                      </div>
                    </div>
                  </template>
                </vue-multiselect>
              </div>

              <div
                v-if="!!answerAction.type && !!answerAction.type.name && !!answerAction.data && answerAction.type.name !== 'end_conversation'"
                class="w-45 my-auto mr-1 text-break"
              >
                <vue-multiselect
                  :id="'object-id' + answerChoiceId + index"
                  v-model="answerAction.data.id"
                  :options="getOptionsForObjectSelection(answerAction.type.name)"
                  :placeholder="getPlaceholder(answerAction.type.name)"
                  :show-labels="false"
                  :custom-label="id => getObjectLabel(id, answerAction.type.name)"
                >
                  <template slot="singleLabel"
                            slot-scope="{option}"
                  >
                    <div>
                      {{ getObjectLabel(option, answerAction.type.name) }}
                    </div>
                  </template>
                </vue-multiselect>
              </div>

              <bao-delete-button
                :ref="'delete-answer-action-btn' + answerChoiceId + index"
                :id="'delete-answer-action-btn' + answerChoiceId + index"
                class="my-auto mr-1"
                @delete="removeAction(index)"
              >
              </bao-delete-button>
            </div>

            <div v-if="answerAction.type && answerAction.type.name === 'custom' && isGlobalAdmin" class="my-2">
              <code>function (vueComponent, storeFuncs, answerPBItem, targetPBItem) {</code>
              <b-form-textarea
                id="customAnswerActionCode-202202131704"
                v-model="answerAction.data.code"
                class="m-2 w-100"
                placeholder="Your custom code..."
              ></b-form-textarea>
              <code>}</code>
            </div>

            <!-- Target Item for the additional playbook -->
            <div v-if="answerAction.type && answerAction.type.name === 'add_playbook'"
                 class="my-2">
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex w-45 mr-1 my-auto">
                  {{ staticText.insertAtLabel }}:
                </div>

                <div class="w-45 my-auto mr-1">
                  <vue-multiselect
                    :id="'target-item-id' + answerChoiceId + index"
                    v-model="answerAction.data.targetId"
                    :options="getOptionsForObjectSelection(null)"
                    :placeholder="staticText.selectTargetItem"
                    :show-labels="false"
                    :custom-label="id => getObjectLabel(id, null)"
                  >
                    <template slot="singleLabel"
                              slot-scope="{option}"
                    >
                      <div>
                        {{ getObjectLabel(option, null) }}
                      </div>
                    </template>
                  </vue-multiselect>
                </div>

                <bao-delete-button
                  :ref="'delete-answer-action-btn' + answerChoiceId + index"
                  :id="'delete-answer-action-btn' + answerChoiceId + index"
                  class="my-auto mr-1"
                  :show-confirm="false"
                  @delete="answerAction.data.targetId = undefined"
                >
                </bao-delete-button>
              </div>
            </div>

            <!-- Target Answer on Item for Set Answer action  -->
            <div v-if="!!answerAction.data && !!answerAction.data.id && answerAction.type.name === 'set_answer'"
                 class="my-2">
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex w-45 mr-1 my-auto">
                  {{ staticText.answerToSetLabel }}:
                </div>

                <div class="w-45 my-auto mr-1">
                  <bao-vue-multi-select
                    :id="'target-item-id' + answerChoiceId + index"
                    v-model="answerAction.data.targetId"
                    :options="getAnswerOptions(answerAction)"
                    :placeholder="staticText.selectTargetAnswer"
                    :show-labels="false"
                    :multiple="isMultiSelect(answerAction)"
                    label="label"
                    track-by="id"
                  ></bao-vue-multi-select>
                </div>

                <bao-delete-button
                  :ref="'delete-answer-action-btn' + answerChoiceId + index"
                  :id="'delete-answer-action-btn' + answerChoiceId + index"
                  class="my-auto mr-1"
                  :show-confirm="false"
                  @delete="answerAction.data.targetId = undefined"
                >
                </bao-delete-button>
              </div>
            </div>

            <div v-if="answerAction.error"
                 class="invalidClass mb-1 ml-1 text-center"
            >
              {{ answerAction.error }}
            </div>
          </div>
        </transition-group>
      </draggable>

      <!-- Add new answer-action button -->
      <div class="content-wrapper p-2 d-flex justify-content-between">
        <h6 class="my-auto">
          {{ staticText.addNewAction }}
        </h6>

        <b-btn :id="'add-new-action-btn-202104120125-'+ answerChoiceId"
               variant="danger"
               class="add-new-item-btn"
               @click="addEmptyAction"
        >
          {{ staticText.add }}

          <img src="/img/icons/light-plus-icon.svg"
               class="mb-1"
          />
        </b-btn>
      </div>
    </div>

    <!-- Discard and Save buttons -->
    <div class="mt-4 text-center d-flex justify-content-between">
      <b-btn :id="'cancel-btn-202104051221-'+ answerChoiceId"
             variant="secondary"
             class="font-size-14 px-4 m-1"
             :disabled="saving"
             @click="cancel"
      >
        {{ staticText.discardAndClose }}
      </b-btn>

      <b-btn :id="'save-answer-actions-btn-202104051221-'+ answerChoiceId"
             variant="primary"
             class="font-size-14 px-4 m-1"
             :disabled="saving"
             @click="saveActions"
      >
        {{ staticText.saveAndClose }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import VueMultiselect from "vue-multiselect"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import axios from "axios"
import { mapGetters } from "vuex"
import { actionTypes, capitalizeFirstLetter } from "./utils"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import ProgressIndicator from "@/apps/base/ProgressIndicator"

export default {
  name: "AnswerActionsConfigurator",
  components: {
    BaoVueMultiSelect,
    draggable,
    BaoDeleteButton,
    VueMultiselect,
    ProgressIndicator
  },
  props: {
    playbookItemId: {
      type: [String, Number],
      required: true
    },
    value: { // answerActions for an answer
      type: Array,
      required: true
    },
    answerChoiceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      drag: false,
      answerActions: [],
      actionTypes,
      staticTextDefault: {
        discardAndClose: "Discard & Close",
        saveAndClose: "Save & Close",
        selectAction: "Select Action",
        selectItem: "Select Item",
        selectPlaybook: "Select Playbook",
        selectTargetItem: "Select Target Item",
        noItemError: "Please select the item to apply the action on",
        multipleJumpsError: "Multiple Jump actions not allowed",
        add: "Add",
        addNewAction: "Add New Action",
        itemNotFound: "Item not found",
        jump: "Jump to",
        collapse: "Collapse",
        expand: "Expand",
        objection: "Objection",
        add_playbook: "Add Playbook",
        end_conversation: "End Conversation",
        set_answer: "Set Answer",
        jumpIncompatibilityLabel: "incompatible with Jump",
        collapseIncompatibilityLabel: "incompatible with Collapse",
        insertAtLabel: "Insert playbook at item",
        answerToSetLabel: "Answer(s) to set",
        selectTargetAnswer: "Select Target Answer(s)"
      }
    }
  },
  computed: {
    ...mapGetters({
      allPlaybooks: "playbook/getAllPlaybooks",
      isGlobalAdmin: "auth/isGlobalAdmin",
      rootContainer: "playbookConfiguratorStore/getRootContainer"
    }),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    playbookItems () {
      return this.rootContainer.children ? this.rootContainer.children.filter(item => !!item.id) : []
    },
    playbookObjections () {
      return this.rootContainer.objections ? this.rootContainer.objections.filter(item => !!item.id) : []
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    answerActionsCollapseIndexes () {
      // This is important to track when collapse has been selected as an answer action
      const indexes = []
      for (const index in this.answerActions) {
        if (this.answerActions[index].type && this.answerActions[index].type.name === "collapse") {
          indexes.push(Number(index))
        }
      }
      return indexes
    },
    answerActionsJumpIndexes () {
      // This is important to track when jump has been selected as an answer action
      const indexes = []
      for (const index in this.answerActions) {
        if (this.answerActions[index].type && this.answerActions[index].type.name === "jump") {
          indexes.push(Number(index))
        }
      }
      return indexes
    },
    defaultAnswerActionTypes () {
      const defaultAnswerActionTypes = this.isGlobalAdmin ? Object.keys(actionTypes) : Object.keys(actionTypes).filter(aaType => aaType !== "custom")
      const options = []
      // The options have to be changed from an array of strings to an array of objects to use the disabled property of vue-multiselect
      for (const answerActionType of defaultAnswerActionTypes) {
        options.push({
          name: answerActionType,
          $isDisabled: false
        })
      }
      return options
    },
    answerActionTypeCollapseIndex () {
      return this.defaultAnswerActionTypes.findIndex(answerActionType => answerActionType.name === "collapse")
    },
    answerActionTypeJumpIndex () {
      return this.defaultAnswerActionTypes.findIndex(answerActionType => answerActionType.name === "jump")
    }
  },
  watch: {
    value (newValue) {
      this.answerActions = (JSON.parse(JSON.stringify(newValue))).map(this.addFrontendKey)
    }
  },
  mounted () {
    this.transformAnswerActions()
  },
  methods: {
    transformAnswerActions () {
      this.answerActions = (JSON.parse(JSON.stringify(this.value))).map(this.addFrontendKey)
      for (const index in this.answerActions) {
        if (this.answerActions[index].type === "add_playbook") {
          this.answerActions[index].data.id = this.answerActions[index].objectId
        }
        // Vue-multiselect has a disabled option which we need with making collapse and jump mutually exclusive, but to access
        // this, we have to change the options passed to vue-multiselect from an array of strings to an array of objects. And because
        // these options directly affect the answer actions, I have to implement this little manipulation which is corrected just before
        // emitting the answer actions when the user saves
        this.answerActions[index].type = {
          name: this.answerActions[index].type,
          $isDisabled: false

        }
      }
    },
    getAnswerActionTypes (index) {
      if (this.answerActionsCollapseIndexes.length > 0 && this.answerActionsJumpIndexes.length > 0) {
        if ((index !== this.answerActionsCollapseIndexes[0] && index !== this.answerActionsJumpIndexes[0]) || this.answerActionsCollapseIndexes.length !== 1 || this.answerActionsJumpIndexes.length !== 1) {
          if (this.answerActions[index].type && this.answerActions[index].type.name === "collapse") return this.getEditedAnswerActionTypes([this.answerActionTypeJumpIndex])
          if (this.answerActions[index].type && this.answerActions[index].type.name === "jump") return this.getEditedAnswerActionTypes([this.answerActionTypeCollapseIndex])
          else return this.getEditedAnswerActionTypes([this.answerActionTypeJumpIndex, this.answerActionTypeCollapseIndex])
        }
      } else if (this.answerActionsCollapseIndexes.length > 0 && (index !== this.answerActionsCollapseIndexes[0] || this.answerActionsCollapseIndexes.length !== 1)) {
        return this.getEditedAnswerActionTypes([this.answerActionTypeJumpIndex])
      } else if (this.answerActionsJumpIndexes.length > 0 && (index !== this.answerActionsJumpIndexes[0] || this.answerActionsJumpIndexes.length !== 1)) {
        return this.getEditedAnswerActionTypes([this.answerActionTypeCollapseIndex])
      }
      return this.defaultAnswerActionTypes
    },
    getEditedAnswerActionTypes (answerActionTypeIndexArray) {
      const editedAnswerActionTypes = JSON.parse(JSON.stringify(this.defaultAnswerActionTypes))
      for (const answerActionTypeIndex of answerActionTypeIndexArray) {
        editedAnswerActionTypes[answerActionTypeIndex].$isDisabled = true
      }
      return editedAnswerActionTypes
    },
    getActionIcon (actionType) {
      try {
        return this.actionTypes[actionType].icon
      } catch (error) {
        return null
      }
    },
    getActionLabel (actionType) {
      return this.staticText[actionType] || capitalizeFirstLetter(actionType)
    },
    getActionCompatibilityLabel (actionType, actionTypeDisabilityStatus) {
      if (actionTypeDisabilityStatus) {
        if (actionType === "jump") {
          return this.staticText.collapseIncompatibilityLabel
        }
        if (actionType === "collapse") {
          return this.staticText.jumpIncompatibilityLabel
        }
      }
    },
    validate (answerActions) {
      let errorExists = false
      let jumpCount = 0
      for (const answerAction of answerActions) {
        if (answerAction.type.name === "jump") {
          jumpCount += 1
        }
        if (answerAction.type.name === "end_conversation") continue
        if (!answerAction.data || !answerAction.data.id) {
          this.$set(answerAction, "error", this.staticText.noItemError)
          errorExists = true
        } else if (answerAction.type.name === "jump" && jumpCount > 1) {
          this.$set(answerAction, "error", this.staticText.multipleJumpsError)
          errorExists = true
        } else {
          this.$set(answerAction, "error", null)
        }
      }
      return !errorExists
    },
    async saveActions () {
      this.removeEmptyActions()
      if (!this.validate(this.answerActions)) return

      this.saving = true

      for (const index in this.answerActions) {
        this.answerActions[index].order = parseInt(index) + 1
        const objectId = this.answerActions[index].data.id

        this.answerActions[index].objectId = objectId
        // As referenced in the comments above (lines 283-286), the answer actions are corrected; the type is reversed
        // back from an object, to a string as it should be.
        this.answerActions[index].type = this.answerActions[index].type.name
        // Need to store each add_playbook type as additional data choice and I would need the data.id to be the adcId
        // I had transformed each add_playbook type from mounted which made the data.id to be the talkscriptId
        // But I still need the talkscript Id which is why I'm creating a new key for it (objectId)
        if (this.answerActions[index].type === "add_playbook") {
          const currentPlaybook = this.allPlaybooks.find(playbook => playbook.id === objectId)
          this.answerActions[index].data.id = await this.addAdditionalDataChoiceAndGetID(objectId, currentPlaybook)
        }
      }
      this.$emit("input", this.answerActions)
      this.$emit("close")
      this.saving = false
    },
    async addAdditionalDataChoiceAndGetID (talkscriptId, currentPlaybook) {
      const { data } = await axios.post("/api/objectionchoices", {
        talkscript: talkscriptId,
        name: currentPlaybook.name
      })
      return data.id
    },
    cancel () {
      this.$emit("close")
    },
    handleActionTypeSelected (answerAction) {
      this.$set(answerAction, "data", {})
      this.$set(answerAction.data, "id", "")
    },
    handleActionTypeRemoved (index) {
      this.removeAction(index)
    },
    removeAction (index) {
      this.answerActions.splice(index, 1)
    },
    isMultiSelect (answerAction) {
      // if answer action relates to playbook item and answers on the selected playbook Item
      const multiSelectTypes = ["question", "rated_multiselect", "multi_select_dropdown"]

      if (answerAction && answerAction.data && answerAction.data.id) {
        const selectedPlaybookItem = this.playbookItems.find(item => item.id === answerAction.data.id)
        return selectedPlaybookItem ? multiSelectTypes.includes(selectedPlaybookItem.item_type) : false
      }

      return false
    },
    getAnswerOptions (answerAction) {
      return this.playbookItems.find(item => item.id === answerAction.data.id).answer_choices
    },
    getOptionsForObjectSelection (actionType) {
      return this.getOptionsBasedOnActionType(actionType).map(item => item.id)
    },
    addFrontendKey (item) {
      item.frontendkey = item.id || Math.random().toString(36).substring(2, 15)
      return item
    },
    addEmptyAction () {
      this.answerActions.push(this.addFrontendKey({}))
    },
    getOptionsBasedOnActionType (actionType) {
      if (actionType === "objection") {
        return this.playbookObjections
      }
      if (actionType === "add_playbook") {
        return this.allPlaybooks
      }
      if (actionType === "set_answer") {
        /*
          exclude playbook items
          - with no answers
          - playbook item which contains current answer in order to avoid conflicting answer selection based on click
          event and answer action.
        */
        return this.playbookItems.map((item, index) => {
          return { ...item, order: index + 1 }
        }).filter(item => item.answer_choices.length > 0 && item.id !== this.playbookItemId)
      }
      return this.playbookItems
    },
    getPlaceholder (actionType) {
      if (actionType === "add_playbook") {
        return this.staticText.selectPlaybook
      }
      return this.staticText.selectItem
    },
    getObjectLabel (id, actionType) {
      const objectsList = this.getOptionsBasedOnActionType(actionType)
      const index = objectsList.findIndex(item => item.id === id)
      if (index > -1) {
        const obj = objectsList[index]
        if (actionType === "set_answer") {
          return `${obj.order} - ${obj.name}`
        }
        if (actionType === "add_playbook") {
          return `${obj.name} (ID: ${id})`
        }
        return `${index + 1} - ${obj.name}`
      }
      return this.staticText.itemNotFound
    },
    removeEmptyActions () {
      let index = 0
      while (index < this.answerActions.length) {
        if (Object.keys(this.answerActions[index]).length === 1) {
          this.answerActions.splice(index, 1)
        } else {
          index++
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.content-wrapper {
  background-color: $white60;
  border-radius: 20px;
}

.add-new-item-btn {
  padding: 6px;
}

.add-new-item-btn img {
  padding: 9px;
  background-color: $orange;
  border-radius: 20px;
}

.opacity-50 {
  opacity: 0.5;
}

</style>

import { mapGetters } from "vuex"

const PlaybookItemMixin = {
  name: "PlaybookItemMixin",
  data () {
    return {
      activities: {
        phoneCall: "phone_call",
        meeting: "meeting",
        task: "taskcreator",
        event: "createCrmEvent"
      },
      staticTextDefault: {
        textOnlyLabel: "Text",
        checkBoxesLabel: "Checkboxes",
        dropdownLabel: "Dropdown-List",
        singleSelectRadio: "Radio-Button",
        ratedCheckBoxesLabel: "Checkboxes (Rated)",
        crmTaskCreatorLabel: "CRM-Task",
        activitiesLabel: "CRM-Activity",
        crmEventCreatorLabel: "CRM-Event",
        taskLabel: "Task",
        eventLabel: "Event",
        phoneCallLabel: "Phone Call",
        meetingLabel: "Meeting",
        crmItemLabel: "CRM-Item",
        dynamicPlaybookLoaderLabel: "Dynamic Playbook Loader",
        singleSelectGroupLabel: "Single-Select",
        multiSelectGroupLabel: "Multi-Select",
        otherGroupLabel: "Other",
        dateTimePickerLabel: "Date/time picker",
        playbookItemError: "Error occurred in saving a playbook item. ",
        shortcutItemError: "Error occurred in saving a shortcut item. ",
        haveError: " have error.",
        item: "Item",
        items: "Items",
        itemHasNoNameErr: " has an invalid name",
        invalidCrmLinkError: " has an invalid CRM field configuration."
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      getCrmObjectId: "crmStore/getCrmObjectId",
      crmIntegrations: "auth/crmIntegrations",
      canUseCrmEvent: "auth/canUseCrmEvent",
      canUseCrmTask: "auth/canUseCrmTask",
      canUsePhoneCall: "auth/canUsePhoneCall",
      canUseMeeting: "auth/canUseMeeting"

    }),
    staticTextMixin () {
      return this.$store.getters["I18nStore/getI18n"]("PlaybookItemMixin", this.staticTextDefault)
    },
    features () {
      return this.user.permissions.can_use_features || []
    },
    ifMultipleActivities () {
      const featuresSet = new Set(this.features)
      const activitiesSet = new Set(Object.values(this.activities))

      // Find the intersection between features and activities
      const commonElements = new Set([...featuresSet].filter(x => activitiesSet.has(x)))

      return commonElements.size >= 2
    },
    crmTaskActivity () {
      return {
        value: "datetimepicker",
        text: this.ifMultipleActivities ? this.staticTextMixin.taskLabel : this.staticTextMixin.crmTaskCreatorLabel
      }
    },
    crmEventActivity () {
      return {
        value: "crm_event",
        text: this.ifMultipleActivities ? this.staticTextMixin.eventLabel : this.staticTextMixin.crmEventCreatorLabel
      }
    },
    phoneCallActivity () {
      return {
        value: this.activities.phoneCall,
        text: this.staticTextMixin.phoneCallLabel
      }
    },
    meetingActivity () {
      return {
        value: this.activities.meeting,
        text: this.staticTextMixin.meetingLabel
      }
    },
    activityTypes () {
      const activities = []
      if (this.canUseCrmTask) activities.push(this.crmTaskActivity)
      if (this.canUseCrmEvent) activities.push(this.crmEventActivity)
      if (this.canUsePhoneCall) activities.push(this.phoneCallActivity)
      if (this.canUseMeeting) activities.push(this.meetingActivity)

      return activities
    }
  },
  methods: {
    playbookItemTypes (inObjection = false) {
      const playbookItemTypes = [
        {
          label: this.staticTextMixin.singleSelectGroupLabel,
          options: [
            {
              value: "single_select_radio",
              text: this.staticTextMixin.singleSelectRadio
            },
            {
              value: "single_select_dropdown",
              text: this.staticTextMixin.dropdownLabel
            }
          ]
        },
        {
          label: this.staticTextMixin.multiSelectGroupLabel,
          options: [
            {
              value: "question",
              text: this.staticTextMixin.checkBoxesLabel
            },
            {
              value: "rated_multiselect",
              text: this.staticTextMixin.ratedCheckBoxesLabel
            },
            {
              value: "multi_select_dropdown",
              text: this.staticTextMixin.dropdownLabel
            }
          ]
        },
        {
          label: this.staticTextMixin.otherGroupLabel,
          options: [
            {
              value: "static",
              text: this.staticTextMixin.textOnlyLabel
            }
          ]
        }

        // {
        //   value: "datetime_select",
        //   text: this.staticTextMixin.dateTimePickerLabel
        // }  todo: uncomment and use this if we need to use datetime_select option in future (backend setup is already there)
      ]
      if (this.ifMultipleActivities) {
        playbookItemTypes[2].options.push({
          value: "activities",
          text: this.staticTextMixin.activitiesLabel,
          subTypes: this.activityTypes
        })
      } else playbookItemTypes[2].options.push(...this.activityTypes)

      if (this.features && this.features.indexOf("crmlink") >= 0) {
        playbookItemTypes[2].options.push({
          value: "crmlink",
          text: this.staticTextMixin.crmItemLabel
        })
      }
      if (!inObjection) {
        playbookItemTypes[2].options.push({
          value: "playbookloader",
          text: this.staticTextMixin.dynamicPlaybookLoaderLabel
        })
      }
      return playbookItemTypes
    },
    getCRMObjectLink (val = null) {
      /* Retrieves CRMObjectLinks as a list (without an id) or with the fieldDefinitions if an id is provided */
      return new Promise((resolve) => {
        let url = "/api/crmobjectlinks"
        if (val !== null) {
          url += "/" + val.id
        }
        this.axios.get(url).then(response => {
          console.debug(response.data)
          if (val === null) {
            this.objectDefinitions = response.data.results ? response.data.results : []
          } else {
            resolve(response.data.field_definitions)
          }
        }).catch(error => {
          this.onObjectDefinitionError(error, val.label)
        }).finally(resolve)
      })
    },
    retrieveFieldDefinitions (array) {
      /* Retrieves one or more field definitions for the provided CRM Object Links */
      const diff = {}
      for (const i in array) {
        const val = array[i]
        if (!this.fieldDefinitions[val.id]) diff[val.id] = val
      }
      if (diff && Object.keys(diff).length > 0) {
        this.loadingFieldDefinitions = []
        const promiseArray = []
        for (const id in diff) {
          this.loadingFieldDefinitions.push(id)
          promiseArray.push(this.getCRMObjectLink(diff[id]).then(fieldDefinitions => {
            this.$set(this.fieldDefinitions, id, fieldDefinitions)
          }))
        }
        Promise.all(promiseArray).then(() => {
          this.loadingFieldDefinitions = []
        })
      }
    },
    getTaskFieldDefinitions () {
      const that = this
      // Only loads salesforce task fields for now
      const getAndTransformData = (resolve) => {
        const crmObjectId = that.getCrmObjectId
        const url = "/api/crmobjectlinks/" + crmObjectId
        if (url != null) {
          that.axios.get(url).then(response => {
            const data = response.data.field_definitions.filter(fieldDefinition => {
              return !fieldDefinition.readonly && fieldDefinition.supported
            }).map(fieldDefinition => {
              return {
                label: fieldDefinition.label,
                raw_definition: fieldDefinition,
                crm_object_link: { id: crmObjectId, key: response.data.service.key },
                logo: response.data.service.extra_options.logo
              }
            })
            resolve(data)
          })
        }
      }
      // If object definitions weren't loaded previously, load them first
      if (this.objectDefinitions.length === 0) {
        return new Promise((resolve, reject) => {
          this.getCRMObjectLink().then(() => {
            getAndTransformData(resolve)
          }).catch((error) => {
            that.onObjectDefinitionError(error, "Task")
            reject(error)
          })
        })
      }
      return new Promise((resolve) => {
        getAndTransformData(resolve)
      })
    },
    getGlobalErrorMsg (errorDetails, isObjection) {
      let globalErrorMsg = isObjection ? this.staticTextMixin.shortcutItemError : this.staticTextMixin.playbookItemError
      globalErrorMsg += errorDetails.length > 1 ? this.staticTextMixin.items : this.staticTextMixin.item
      for (const error of errorDetails) {
        globalErrorMsg += "<a href=#" + error.itemLink + "> " + error.position + "</a>,"
      }
      globalErrorMsg += this.staticTextMixin.haveError
      return globalErrorMsg
    },
    validatePlaybookItem (playbookItem) {
      this.resetItemError(playbookItem)
      let error = null
      if (!playbookItem.name.trim()) {
        error = this.staticTextMixin.item + this.staticTextMixin.itemHasNoNameErr
        this.setItemError(playbookItem, error)
        return true
      }
      const isCRMLink = playbookItem.item_type === "crmlink"
      const linkedFieldValid = !!playbookItem.linked_field &&
        !!playbookItem.linked_field.crm_object_link &&
        !!playbookItem.linked_field.field_definition &&
        !!playbookItem.linked_field.field_definition.length
      if (isCRMLink && !linkedFieldValid) {
        error = this.staticTextMixin.item + this.staticTextMixin.invalidCrmLinkError
        this.setItemError(playbookItem, error)
        return true
      }
      return false
    },
    resetItemError (item) {
      this.$set(item, "error", null)
    },
    setItemError (item, error) {
      this.$set(item, "error", error)
    },
    validatePlaybookChildren (itemArray) {
      const errorPositions = []
      for (const index in itemArray) {
        const playbookItem = itemArray[index]
        const errorMsgForItem = this.validatePlaybookItem(playbookItem)
        if (errorMsgForItem) {
          errorPositions.push({ position: parseInt(index) + 1, itemLink: playbookItem.uniqueId })
        }
      }
      return errorPositions
    }
  }
}

export default PlaybookItemMixin

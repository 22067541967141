export const actionTypes = {
  jump: {
    icon: "fas fa-sign-out-alt"
  },
  collapse: {
    icon: "fas fa-chevron-up"
  },
  expand: {
    icon: "fas fa-chevron-down"
  },
  objection: {
    icon: "fas fa-bolt"
  },
  add_playbook: {
    icon: "fas fa-play-circle"
  },
  custom: {
    icon: "fas fa-solid fa-code"
  },
  end_conversation: {
    icon: "fas fa-door-open"
  },
  set_answer: {
    icon: "fas fa-sign-out-alt"
  }
}

export function capitalizeFirstLetter (string) {
  return string && string.length ? string.charAt(0).toUpperCase() + string.slice(1) : ""
}

export function isTextTruncated (elementId) {
  const element = document.getElementById(elementId)
  if (element) {
    return (element.offsetWidth < element.scrollWidth)
  } else return false
}

export function caseInsensitiveLooseEqual (string1, string2) {
  /**
     * convert both values to lower case and to string and compare if they are same
     * equality check returns true for following cases otherwise returns strict equality value
     *  - "Abc"==="abc" <- case-insensitive equality
     *  - 123==="123" <- loose equality
     * */
  return !!string1 && !!string2
    ? string1.toString().toLowerCase() === string2.toString().toLowerCase()
    : string1 === string2
}

export const PLAYBOOK_ITEM_DRAGGABLE_HANDLE = "playbook-draggable-handle"

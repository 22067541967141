<template>
  <div id="add-item-button-div-202110171814"
       class="add-item-button-wrapper d-flex justify-content-between align-items-center"
  >
    <b-btn id="add-new-pb-item-btn-202101231125"
           class="add-new-item-btn px-2"
           :disabled="!!showLibraryItemSelection"
           @click="$emit('create')"
    >
      <img src="/img/icons/light-plus-icon.svg"
      />
      {{ staticText.addNewItemBtnLabel }}
    </b-btn>

    <div :id="'add-existing-pb-item-btn-wrapper-202101231125'+mainContainerId">
      <b-btn
        v-if="!showLibraryItemSelection"
        id="add-existing-pb-item-btn-202101231125"
        class="add-existing-item-btn px-2"
        :disabled="getFilteredLibraryItems.length===0"
        @click="showLibraryItemSelection=true"
      >
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'svg-link-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.778 1.286C5.778.576 6.375 0 7.11 0H8.89c.736 0 1.333.576 1.333 1.286V3c0 .71-.597 1.286-1.333 1.286h-.222v1.071h4.222c.858 0 1.555.672 1.555 1.5v.857h.223C15.403 7.714 16 8.29 16 9v1.714c0 .71-.597 1.286-1.333 1.286h-1.778c-.736 0-1.333-.576-1.333-1.286V9c0-.71.597-1.286 1.333-1.286h.222v-.857c0-.118-.1-.214-.222-.214H8.667v1.071h.222c.736 0 1.333.576 1.333 1.286v1.714c0 .71-.597 1.286-1.333 1.286H7.11c-.736 0-1.333-.576-1.333-1.286V9c0-.71.597-1.286 1.333-1.286h.222V6.643H3.111a.219.219 0 00-.222.214v.857h.222c.736 0 1.333.576 1.333 1.286v1.714c0 .71-.597 1.286-1.333 1.286H1.333C.597 12 0 11.424 0 10.714V9c0-.71.597-1.286 1.333-1.286h.223v-.857c0-.828.697-1.5 1.555-1.5h4.222V4.286h-.222c-.736 0-1.333-.576-1.333-1.286V1.286z" fill="#555775"/></svg>
        {{ staticText.addExistingItemLabel }}
      </b-btn>
    </div>
    <b-tooltip
      v-if="getFilteredLibraryItems.length===0"
      :target="'add-existing-pb-item-btn-wrapper-202101231125'+mainContainerId"
    >
      {{ staticText.noExistingItemsLabel }}
    </b-tooltip>
    <div v-if="!!showLibraryItemSelection"
         class="my-auto"
    >
      <progress-indicator
        v-if="loadingLibraryItems"
        :show-loading-label="false"
        :small="true"
      ></progress-indicator>

      <vue-multiselect
        v-if="showLibraryItemSelection"
        ref="multiselectRef-202206080254"
        v-model="selectedLibraryItem"
        :options="getFilteredLibraryItems"
        :show-labels="false"
        :placeholder="staticText.selectPlaybookItemsLabel"
        track-by="id"
        label="name"
        class="existing-item-multiselect"
        autofocus
        @select="handleLibraryItemSelected"
        @close="showLibraryItemSelection = false"
        @open="showLibraryItemSelection = true"
      >
        <span slot="noResult">
          <span class="w-100">{{staticText.noResults}}</span>
        </span>
      </vue-multiselect>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import progressIndicator from "@/apps/base/ProgressIndicator"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "NewItemControls",
  components: { VueMultiselect, progressIndicator },
  props: {
    playbookItems: {
      type: Array,
      required: true,
      default: () => []
    },
    mainContainerId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      staticTextDefault: {
        selectPlaybookItemsLabel: "Select existing Item",
        addNewItemBtnLabel: "Add new Item",
        addExistingItemLabel: "Add existing Item",
        noExistingItemsLabel: "No existing Items available",
        noResults: "No results found. Consider changing search query"
      },
      showLibraryItemSelection: false,
      selectedLibraryItem: null
    }
  },
  computed: {
    ...mapGetters({
      loadingLibraryItems: "playbookConfiguratorStore/getLoadingLibraryItems",
      libraryItems: "playbookConfiguratorStore/getLibraryItems"
    }),
    getFilteredLibraryItems () {
      return this.libraryItems.filter(libraryItem => !this.playbookItems.some(item => item.id === libraryItem.id))
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    showLibraryItemSelection (val) {
      if (val) {
        // second time when add existing item button is clicked, we reset the selected value
        this.selectedLibraryItem = null
        this.$nextTick(() => {
          // This is required to focus the input field and open the dropdown when the button is clicked because
          // autofocus attribute on vue-multiselect works only the first time after render of the component
          // nextTick is required to wait for the vue-multiselect component to render
          this.$refs["multiselectRef-202206080254"].activate()
        })
      }
    }
  },
  mounted () {
    this.getLibraryItems()
  },
  methods: {
    ...mapActions({
      getLibraryItems: "playbookConfiguratorStore/getLibraryItems"
    }),
    handleLibraryItemSelected (selectedItem) {
      this.$emit("link", selectedItem)
      this.showLibraryItemSelection = false
    }
  }
}
</script>

<style scoped lang="scss">

.add-item-button-wrapper {
  margin: 8px 0;
  padding: 6px;
  background-color: $white80;
  border-radius: 12px;
  color: $slate;
}

@mixin add-item-btn {
  padding: 6px;
  background-color: transparent;
  &:hover {
    background-color: $slate10 !important;
  }
  img, svg {
    border-radius: 50%;
    box-shadow: 16px 12px 40px rgba(0, 0, 0, 0.05);
  }
}

.add-new-item-btn {
  @include add-item-btn;
  img, svg {
    padding: 9px;
    background-color: $slate;
  }
}

.add-existing-item-btn {
  @include add-item-btn;
  img, svg {
    padding: 0 8px 2px;
    background-color: $slate06;
  }
}

.svg-link-icon{
  height: 32px;
  width: 32px;
}

</style>

<style lang="scss">

.existing-item-multiselect .multiselect__option{
  white-space: unset;
}

.existing-item-multiselect .multiselect__input{
  max-width: 230px;
}

.existing-item-multiselect .multiselect__tags{
  width: 300px !important;
}
</style>

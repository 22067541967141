<template>
  <div v-if="currentItem" class="d-flex align-items-center">
    <!-- Playbook Item type -->
    <div
      v-click-outside="hideDropdownMenu"
      class="types-dropdown"
    >
      <button
        class="types-dropdown__button"
        :disabled="!canModify || systemProtected(currentItem)"
        @click.stop="toggleShowMenu"
      >
        <span>
          {{ currentItemName }}
        </span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" :class="['toggle-dropdown-icon', { 'show' : showMenu }]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.002 7.851a.847.847 0 01.456-.753.86.86 0 01.946.099l4.584 3.808 4.593-3.672a.857.857 0 011.206.127.848.848 0 01-.128 1.241l-5.132 4.106a.858.858 0 01-1.086 0l-5.132-4.25a.85.85 0 01-.307-.706z" fill="#2A2D52"/></svg>
      </button>
      <!-- dropdown menu content -->
      <transition
        mode="out-in"
        name="animate-down"
      >
        <div
          v-if="showMenu"
          class="types-dropdown__menu"
          :class="{'right-58': activityMenu}"
          @click.stop
        >
          <div
            v-for="(group, index) in itemTypes"
            :key="index"
            @click.stop
            class="types-dropdown__menu--group"
            header-classes="p-0 my-2"
          >
            <h2 class="types-dropdown__menu--header">{{ group.label }}</h2>
            <button
              v-for="option in group.options"
              :key="option.value"
              @click.stop="handleTypeChange(option.value, currentItem)"
              class="types-dropdown__menu--item"
            >
              <span class="types-dropdown__menu--item__text">
                {{ option.text }}
                <span v-if="option.value==='activities'">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.351 4.002a.847.847 0 00-.753.456.86.86 0 00.099.946l3.808 4.584-3.672 4.593a.857.857 0 00.127 1.206.848.848 0 001.241-.128l4.106-5.132a.858.858 0 000-1.086l-4.25-5.132a.85.85 0 00-.706-.307z" fill="#555775"/></svg>
                  <div v-if="activityMenu && !!option.subTypes" class="activity-menu">
                    <button v-for="subOption in option.subTypes"
                            :key="subOption.value"
                            class="types-dropdown__menu--item"
                            @click.stop="handleTypeChange(subOption.value, currentItem)"
                    >
                      <span class="types-dropdown__menu--item__text">
                        {{ subOption.text }}
                      </span>
                    </button>
                  </div>
                </span>
              </span>

            </button>
          </div>
        </div>
      </transition>
    </div>

    <!-- Collapse button -->
    <bao-toggle-button
      v-if="!isOpenFromLibraryItemsModal"
      class="collapse-button ml-3"
      type="dropdown"
      :is-active="currentItem.open"
      @toggle="toggleItemOpen(currentItem)"
    />
  </div>
</template>

<script>
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import vClickOutside from "v-click-outside"

export default {
  name: "PlaybookItemControls",
  data () {
    return {
      showMenu: false,
      activityMenu: false,
      staticTextDefault: {
        itemTypePlaceholder: "Select the item type",
        crmActivityLabel: "CRM-Activity"
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    BaoToggleButton
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
      required: true
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    },
    itemTypes: {
      type: Array,
      default: () => [],
      required: true
    },
    isOpenFromLibraryItemsModal: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    currentItemName () {
      let itemName = this.staticText.itemTypePlaceholder

      for (const group of this.itemTypes) {
        for (const option of group.options) {
          if (option.value === this.currentItem.item_type) {
            itemName = option.text
            return itemName
          }
          if (option.subTypes) {
            for (const activity of option.subTypes) {
              if (activity.value === this.currentItem.item_type) {
                itemName = `${this.staticText.crmActivityLabel} / ${activity.text}`
                return itemName
              }
            }
          }
        }
      }

      return itemName
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    systemProtected (item) {
      if (Array.isArray(item.tags) && item.tags.length) {
        return item.tags.includes("system/protected")
      }
      return false
    },
    handleTypeChange (newType, item) {
      if (newType === "activities") {
        this.toggleActivityMenu()
        return
      }
      item.item_type = newType
      // Attached additional talkscript must be reset
      item.additional_talkscripts = []
      item.linked_field = {}
      if (newType === "playbookloader" || newType === "static") {
        item.required = false
      }
      // TODO: this won't be necessary if we remove importance flag from answers
      // and can be handled bychecking item_type during answer choice handling.
      this.$set(item, "with_importance", newType === "rated_multiselect")

      if (newType !== "crmlink") {
        this.$emit("handle-playbook-item-changed", item)
      }
      this.toggleActivityMenu()
      this.toggleShowMenu()
    },
    toggleItemOpen (item) {
      item.open = !item.open
    },
    toggleShowMenu () {
      this.showMenu = !this.showMenu
    },
    toggleActivityMenu () {
      this.activityMenu = !this.activityMenu
    },
    hideDropdownMenu () {
      this.showMenu = false
    }
  }
}
</script>

<style scoped lang="scss">

.collapse-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $white;
  width: 24px;
  height: 24px;
}

.toggle-dropdown-icon {
  transition: 0.3s ease-in;
  &.show {
    transform: rotate(180deg);
  }
}

.types-dropdown {
  position: relative;
  &__button {
    transition: background-color 0.5s ease-in-out;
    border: none;
    border-radius: 3px;
    outline: none;
    background-color: transparent;
    text-align: right;
    color: $slate;
    font-size: 14px;
    &:hover {
      background-color: $slate06;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  &__menu {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background: #FFFFFF;
    box-shadow: 6px 8px 20px 5px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 200px;
    z-index: 99999;
    padding: 16px 8px;
    cursor: default;

    &--group {
      border-bottom: 1px solid rgba(42, 45, 82, 0.06);
      padding-bottom: 8px;
      margin-bottom: 8px;
      &:last-child {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    &--header {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $slate40;
      margin: 0 0 8px 8px;
    }
    &--item {
      display: block;
      font-weight: 400;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      outline: none;
      font-size: 12px;
      line-height: 14px;
      color: $slate80;
      transition: 0.3s ease-in;
      width: 100%;
      text-align: left;
      padding: 8px 18px;
      &:hover {
        border: 1px solid #D4D5DC;
      }
      &__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          height: 14px;
        }
      }
    }
  }

  .activity-menu {
    position: absolute;
    width: 120px;
    left: 204px;
    margin-top: -40px;
    padding: 16px 8px;
    background: #FFFFFF;
    border-radius: 12px;
  }

  .right-58 {
    right: 58px;
  }
}
</style>

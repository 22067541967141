<template>
  <div>
    <div>
      <select v-if="answerChoiceTags" name="selectedItemTypeTag" id="selectedItemTypeTag" v-model="selectedItemTypeTag" @change="updateAnswerTag" class="labels-select">
        <option :value="null" disabled>--{{ staticText.selectTagsLabel }}--</option>
        <option :key="index" v-for="(tag, index) in availableTags" :value="tag.name">{{ toPascalCase(tag.label) }}</option>
      </select>
      <div v-else class="playbook__labels mt-2">
        <div v-for="(label, index) in labels.slice(0, 3)" :key="index">
          <div v-if="shouldShowLabel(label)">
            <div class="label">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.878 0h2.734c.31 0 .607.123.826.343l3.22 3.218c.456.457.456 1.2 0 1.657l-2.44 2.44c-.458.456-1.2.456-1.657 0L.343 4.437A1.167 1.167 0 010 3.612V.878C0 .393.393 0 .878 0zm1.17 2.633a.585.585 0 10.002-1.169.585.585 0 00-.002 1.17z" fill="#2A2D52"/></svg>
              <span class="mr-1">{{ getTransformedLabel(label) }}</span>
              <span v-if="!systemProtectedTags" class="cancel" @click="remove(label, index)">
                <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
              </span>
            </div>
          </div>
        </div>
        <div v-if="labels.length > 3">
          <span
            :id="'moreLabels-' + itemIdentifier"
            class="more_labels"
          >
            +{{labels.length - 3}} {{ staticText.moreLabel }}
          </span>
          <b-tooltip :target="'moreLabels-' + itemIdentifier"
                     custom-class="custom-tooltip"
          >
            <div class="playbook__labels">
              <div v-for="(label, index) in labels.slice(3)" :key="index">
                <div v-if="shouldShowLabel(label)" class="label">
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.878 0h2.734c.31 0 .607.123.826.343l3.22 3.218c.456.457.456 1.2 0 1.657l-2.44 2.44c-.458.456-1.2.456-1.657 0L.343 4.437A1.167 1.167 0 010 3.612V.878C0 .393.393 0 .878 0zm1.17 2.633a.585.585 0 10.002-1.169.585.585 0 00-.002 1.17z" fill="#2A2D52"/></svg>
                  <span class="mr-1">{{ getTransformedLabel(label) }}</span>
                  <span v-if="!systemProtectedTags" class="cancel" @click="remove(label, index + 3)">
                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
                  </span>
                </div>
              </div>
            </div>
          </b-tooltip>
        </div>
        <div v-if="!systemProtectedTags" class="playbook__labels--addlabel">
          <transition mode="out-in"
                      name="fade"
                      v-if="addLabelShow === true"
          >
            <div
              class="search"
              v-click-outside="closeAddLabelsDropdown"
            >
              <form @submit.prevent="handleValueSelected(addLabelSearch)">
                <input class="input"
                       autofocus
                       v-model="addLabelSearch"
                       :placeholder="staticText.addTagLabel"
                       type="text"
                >
              </form>
              <div class="search_container">
                <p v-if="canShowTag(addLabelSearch)"
                   @click="handleValueSelected(addLabelSearch)"
                >
                  {{ addLabelSearch }}
                </p>
                <p v-for="(tag, index) in filteredLabels"
                   :key="index"
                   @click="handleValueSelected(tag.label)"
                >
                  {{tag.label}}
                </p>
              </div>
            </div>
          </transition>
          <p v-else
             class="add"
             @click="addLabels"
          >
            + {{ staticText.addTagsLabel }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vClickOutside from "v-click-outside"

export default {
  name: "BaseTagInput",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      staticTextDefault: {
        moreLabel: "More",
        addTagsLabel: "Add Labels",
        addTagLabel: "Add Label",
        selectTagsLabel: "Select Label",
        errorTitle: "Invalid Input",
        errorDescriptionMessage: "Labels may only contain letters, numbers and spaces."
      },
      addLabelShow: false,
      addLabelSearch: "",
      selectedItemTypeTag: null,
      itemTypeTagIndex: 0
    }
  },
  props: {
    labels: { // labels for the target item
      type: Array,
      required: true
    },
    availableTags: { // all labels available for all target items
      type: Array,
      required: true
    },
    itemIdentifier: { // unique identifier of the target item to be tagged
      type: [Number, String],
      required: true
    },
    systemProtectedTags: {
      type: Boolean,
      required: false,
      default: false
    },
    answerChoiceTags: {
      required: false,
      type: Boolean,
      default: false
    },
    itemTypeTag: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    filteredLabels () {
      const searchValue = this.addLabelSearch
      const transformedSearchValue = new RegExp(searchValue, "i")
      // The tags array was converted to a dictionary so I can run the filter below in O(n) time complexity
      const tagsObjectClone = {}
      this.labels.forEach(tag => {
        tagsObjectClone[tag] = true
      })
      // In the filter below, first I check that the label is not already in the list of labels of the current playbook, then I check that the label includes
      // the search parameter, then finally check that the tag is not the search parameter
      // eslint-disable-next-line no-prototype-builtins
      return this.availableTags.filter(tag => !tagsObjectClone.hasOwnProperty(tag.name) && transformedSearchValue.test(tag.label) && tag.label.toLowerCase() !== searchValue.toLowerCase())
    }
  },
  mounted () {
    if (this.answerChoiceTags) {
      this.itemTypeTagIndex = this.labels.findIndex(label => label.includes(this.itemTypeTag))
      this.selectedItemTypeTag = this.labels[this.itemTypeTagIndex] ? this.labels[this.itemTypeTagIndex] : null
    }
  },
  methods: {
    updateAnswerTag () {
      this.$emit("update-answer-tag", this.selectedItemTypeTag, this.itemTypeTagIndex)
    },
    transformLabel (label) {
      // The labels returned per item have parent/child structure we split this structure and
      // use only last value if they're answer choice tags else we extract out the parent
      const newLabel = label.split("/")
      if (this.answerChoiceTags) {
        return newLabel[newLabel.length - 1]
      } else {
        newLabel.shift()
        return newLabel.join("/")
      }
    },
    getTagParent (label) {
      const splittedLabel = label.split("/")
      return splittedLabel[0]
    },
    shouldShowLabel (label) {
      return this.getTagParent(label) !== "system"
    },
    remove (removedLabel, index) {
      this.$emit("label-removed", removedLabel, index)
    },
    addLabels () {
      this.addLabelShow = true
      this.addLabelSearch = ""
    },
    closeAddLabelsDropdown () {
      this.addLabelShow = false
      this.addLabelSearch = ""
    },
    async handleValueSelected (newLabel = null) {
      this.hideGlobalToast()
      if (this.validateLabel(newLabel)) {
        if (newLabel === null) newLabel = this.addLabelSearch
        if (!this.checkIfTagExists(newLabel)) {
          this.$emit("label-added", newLabel)
        }
        this.addLabelSearch = ""
      } else {
        this.showErrorToast()
      }
    },
    validateLabel (label) {
      const alphaNumericRegex = /[a-z0-9]/i
      const nonAlphaNumbericWithSpaceRegex = /[^a-z0-9 ]/i
      return !nonAlphaNumbericWithSpaceRegex.test(label) && alphaNumericRegex.test(label)
    },
    showErrorToast () {
      const errorMessage = {
        title: this.staticText.errorTitle,
        description: this.staticText.errorDescriptionMessage
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    },
    checkIfTagExists (newLabel) {
      return this.labels.find(label => this.transformLabel(label).toLowerCase() === newLabel.toLowerCase())
    },
    canShowTag (newLabel) {
      return newLabel && !this.checkIfTagExists(newLabel)
    },
    getTransformedLabel (label) {
      return !this.systemProtectedTags ? this.transformLabel(label) : this.toPascalCase(this.transformLabel(label))
    }
  }
}
</script>

<style scoped lang="scss">

.custom-tooltip > .tooltip-inner {
  max-width: unset !important;
  padding: 8px 2px 8px 8px !important;
}
</style>

<template>
  <div>
    <!-- Text display -->
    <div
      v-if="!openTextEditor"
      class="editable-text-container"
      :style="getExtraStyle"
    >
      <div
        ref="clickable-text-display"
        :class="[
          'editable-text',
          {'show-pen-icon': showPenIcon, 'input-disabled': disabled, 'pr-0': !showPenIcon}
        ]"
        :style="getClickableTextExtraStyle"
        @click.stop="handleTextClicked"
      >
        {{ currentText }}
      </div>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="showPenIcon && !disabled" v-bind:svg-inline="''" v-bind:class="'pen-icon'" @click.stop="handleTextClicked" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.95 2.657l3.254 3.255-8.239 8.239-3.253-3.255L9.95 2.657zm5.724-.785L14.222.421a1.44 1.44 0 00-2.034 0l-1.39 1.39 3.254 3.255 1.622-1.622a1.11 1.11 0 000-1.572zM.009 15.471a.37.37 0 00.448.44l3.627-.88-3.253-3.254-.822 3.694z" fill="#AAABBA"/></svg>
    </div>

    <!-- Text editor-->
    <div v-else>
      <base-alert variant="danger"
                  v-if="!!validationError"
                  :show-alert="!!validationError"
                  :alert-message="validationError"
                  class="mb-1"
      ></base-alert>
      <div
        ref="clickable-text-input"
        id="clickable-text-input"
        :style="getExtraStyle"
        class="editable-text"
        contenteditable
        spellcheck="false"
        @keydown.enter.prevent="$event.target.blur()"
        @blur="event => closeEditor(event)"
        @click.stop
        @input="event => validate(event)"
      >
        {{ currentText }}
      </div>
    </div>
  </div>
</template>

<script>

import BaseAlert from "@/apps/base/BaseAlert"

export default {
  name: "BaoEditableText",
  components: { BaseAlert },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    showPenIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    extraStyle: {
      type: Object,
      default: () => {
      },
      required: false
    },
    clickableTextExtraStyle: {
      type: Object,
      default: () => {
      },
      required: false
    },
    maxCharacterLimit: {
      type: Number,
      required: false
    },
    maxCharacterLimitMessage: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      staticTextDefault: {
        maxCharacterLimitDefaultMessage: "Number of input characters cannot exceed"
      },
      openTextEditor: false,
      previousText: "",
      currentText: "",
      validationError: null
    }
  },
  computed: {
    getExtraStyle () {
      return { "max-width": "400px", ...this.extraStyle }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    getClickableTextExtraStyle () {
      return this.clickableTextExtraStyle
    }
  },
  mounted () {
    this.currentText = this.value
  },
  watch: {
    value () {
      this.currentText = this.value
    }
  },
  methods: {
    validate (event) {
      this.validationError = null // reset validation error
      const inputText = event.target.innerText
      if (this.maxCharacterLimit) {
        if (inputText.length > this.maxCharacterLimit) {
          this.validationError = this.maxCharacterLimitMessage
            ? this.maxCharacterLimitMessage
            : `${this.staticText.maxCharacterLimitDefaultMessage} ${this.maxCharacterLimit}.`
        }
      }
    },
    handleTextClicked () {
      if (this.disabled) return
      this.previousText = this.currentText
      this.openTextEditor = true
      // waiting for the input element to be rendered and then setting the focus
      // and cursor at the end of the text
      this.$nextTick(() => {
        this.setClickableCursorAtEnd()
      })
    },
    closeEditor (event) {
      this.openTextEditor = false
      if (this.validationError) {
        this.validationError = null
        return
      }
      const newText = event.target.innerText
      if (this.previousText && newText === this.previousText) return
      this.currentText = !newText ? this.previousText : newText
      this.$emit("input-changed", this.currentText)
    },
    revertText () {
      this.currentText = this.previousText
    },
    setClickableCursorAtEnd () {
      const editableElement = this.$refs["clickable-text-input"]
      editableElement.focus()
      editableElement.scrollLeft = editableElement.scrollWidth
      const sel = window.getSelection()
      sel.selectAllChildren(editableElement)
      sel.collapseToEnd()
    }
  }
}

</script>

<style scoped lang="scss">

.editable-text-container {
  display: flex;
  align-items: center;
  width: fit-content;
  transition: 0.3s background-color ease-in;
  padding-right: 8px;
  border-radius: 4px;

  &:hover {
    background-color: $slate06;
  }
}

.editable-text {
  border-radius: 4px;
  padding: 2px 8px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  cursor: text;
  width: fit-content;

  &:not(:focus) {
    text-overflow: ellipsis;
  }

  &:focus {
    background-color: $slate06;
  }

  &.input-disabled {
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  &.show-pen-icon {
    max-width: calc(100% - 16px); // 16px is the width of the pen icon
  }
}

svg.pen-icon {
  width: 16px;
  height: 16px;
  cursor: text;

  path {
    fill: $slate80
  }
}

:deep(.alert) {
  margin-left: unset;
}

</style>

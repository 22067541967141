<template>
  <div class="confetti__card">
    <span
      class="confetti__card--cancel-icon"
      role="button"
      @click="$emit('close-confetti')"
    >
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
    </span>
    <h2
      v-html="emoji"
      class="confetti-emoji"
    ></h2>
    <h1 v-if="header">{{ header }}</h1>
    <p>{{ subheading }}</p>
  </div>
</template>

<script>
export default {
  name: "ConfettiCard",
  props: {
    emoji: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: false
    },
    subheading: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      test: "&#x1F643;"
    }
  }
}
</script>

<style lang="scss" scoped>
.confetti {
  &__card {
    position: relative;
    padding: 30px 60px;
    background-color: $white;
    border-radius: 20px;
    box-shadow: 6px 8px 20px $black03;
    text-align: center;
    max-width: 344px;
    z-index: 9999;
    color: $slate;
    h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
    }
    p {
      margin: 0;
    }
    .confetti-emoji {
      font-size: 36px;
    }
    &--cancel-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
}
</style>

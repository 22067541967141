<template>
  <div>
    <b-btn :id="'add-custom-fields-button-' + item.uniqueId"
           v-if="addFieldsBtnVisible"
           variant="secondary"
           class="border-0 py-2"
           @click="addCustomFieldsSelection"
    >
      {{ staticText.addFields }}
    </b-btn>

    <div class="d-flex my-2" v-if="!addFieldsBtnVisible">
      <div class="row col-3 my-auto">
        {{ staticText.additionalFields }}:
      </div>

      <div class="w-100 mx-2">
        <vue-multiselect id="'additional-fields-multiselect-' + currentItem.uniqueId"
                         v-model="selectedFields"
                         label="label"
                         track-by="crm_field_id"
                         :options="taskFields"
                         :close-on-select="false"
                         :multiple="true"
                         :showLabels="false"
                         :placeholder="staticText.selectFields"
                         @close="onChange"
        >
          <template slot="tag"
                    slot-scope="{ option }"
          >
            <span class="custom__tag option__small w-100 d-flex justify-content-left mb-1 mx-2">
              <img :src="option.logo"
                   class="mr-2 my-auto"
                   alt="Crm service logo"
                   width="20"/>

              {{ option.label }}
            </span>
          </template>

          <template slot="option"
                    slot-scope="{option}"
          >
            <div class="d-flex justify-content-left">
              <img :src="option.logo"
                   class="mr-2 my-auto"
                   alt="Crm service logo"
                   width="20"/>

              <div>
                {{ option.label }}
              </div>
            </div>
          </template>
        </vue-multiselect>
      </div>

      <bao-delete-button
        :id="'additional-fields-remove-btn-' + item.uniqueId"
        :tooltip="staticText.removeFieldsBtn"
        :showConfirm="false"
        @delete="removeCustomFieldsSelection"
      ></bao-delete-button>
    </div>
  </div>
</template>

<script>
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import VueMultiselect from "vue-multiselect"
import { mapActions, mapMutations } from "vuex"

export default {
  name: "AdditionalTaskFieldsComponent",
  components: {
    BaoDeleteButton,
    VueMultiselect
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      taskFields: [],
      selectedFields: [],
      addFieldsBtnVisible: true,
      staticTextDefault: {
        addFields: "Add fields",
        additionalFields: "Additional fields",
        selectFields: "Select fields",
        removeFieldsBtn: "Remove fields"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  created () {
    this.setUp()
  },
  methods: {
    ...mapMutations({
      setObjectDefinitionError: "crmStore/setObjectDefinitionError"
    }),
    ...mapActions({
      getFieldsDefinitions: "crmStore/getTaskFieldDefinitions"
    }),
    setUp () {
      const crmItemsLoaded = !this.item.crm_items || this.item.crm_items.length === 0
      if (!crmItemsLoaded) {
        this.loadCrmItems().then(() => {
          this.selectedFields = this.item.crm_items ? this.item.crm_items : []
          // load logos for already selected items
          this.selectedFields = this.selectedFields.map(field => {
            return {
              ...field,
              logo: this.taskFields.find(taskField => taskField.raw_definition.id === field.raw_definition.id).logo,
              crm_field_id: field.raw_definition.id
            }
          })
          this.addFieldsBtnVisible = this.taskFields && this.taskFields.length > 0 && this.selectedFields.length === 0
        })
      }
    },
    addCustomFieldsSelection () {
      if (this.taskFields.length > 0) {
        this.addFieldsBtnVisible = false
        return
      }

      this.loadCrmItems().then(() => {
        this.addFieldsBtnVisible = false
      })
    },
    removeCustomFieldsSelection () {
      this.addFieldsBtnVisible = true
      this.selectedFields = []
      this.onChange()
    },
    onChange () {
      this.$emit("change", this.selectedFields)
    },
    loadCrmItems () {
      const that = this
      return new Promise((resolve) => {
        that.getFieldsDefinitions().then(response => {
          that.taskFields = response.map(field => {
            return { ...field, crm_field_id: field.raw_definition.id }
          })
          resolve()
        }).catch((error) => {
          this.setObjectDefinitionError({ error, label: "Task" })
        })
      })
    }
  }
}
</script>

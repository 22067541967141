<template>
  <div role="group" class="row mb-4">

    <label :id="'label-for-' + id"
           v-if="label"
           :for="id"
    >
      <span v-if="isRequired"
            style="color: red"
      >
        *
      </span>
      {{label}}:
    </label>

    <b-form-input :id="id"
                  v-model="newValue"
                  :type="type"
                  :state="inputState"
                  :aria-describedby="'input-live-feedback-'+id"
                  :placeholder="placeholderText"
                  :readonly="readOnly"
                  @keyup.enter="onEnterKeyPress"
    ></b-form-input>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="'input-live-feedback-'+id">{{invalidFeedback}}</b-form-invalid-feedback>

  </div>
</template>

<script>
export default {
  name: "BaseFormInput",
  props: {
    id: {
      type: String,
      default: "BaseFormInput-ID"
    },
    type: {
      type: String,
      required: false
    },
    value: null,
    label: {
      type: String,
      required: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    feedbackText: {
      type: String,
      required: false
    },
    placeholderText: {
      type: String,
      required: false
    },
    stateFunction: {
      type: Function,
      default: null,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    invalidFeedback: {
      type: String,
      default: "Invalid Input",
      required: false
    },
    onEnterKeyPress: {
      type: Function,
      default: () => {},
      required: false
    }
  },
  data () {
    return {
      newValue: null
    }
  },
  computed: {
    inputState () {
      if (this.stateFunction && typeof (this.stateFunction) === "function") {
        return this.stateFunction(this.newValue)
      }
      return null
    }
  },
  watch: {
    newValue () {
      this.$emit("input", this.newValue)
    },
    value () {
      this.newValue = this.value
    }
  },
  created () {
    this.newValue = this.value
  },
  methods: {
    focus () {
      document.getElementById(this.id).focus()
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div id="loading-screen-blocking-modal-202101081814">
    <b-modal v-model="fallback"
             hide-footer
             hide-header
             no-close-on-esc
             no-close-on-backdrop
             no-stacking
    >
      <progress-indicator :loading-label="loadingLabel"></progress-indicator>
    </b-modal>
  </div>
</template>

<script>
import ProgressIndicator from "./ProgressIndicator"

export default {
  name: "ProgressIndicatorModal",
  components: {
    ProgressIndicator
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingLabel: {
      type: String,
      default: "Loading ..."
    },
    ignoreTimeout: {
      type: Boolean,
      default: false
    },
    timeoutFrame: {
      type: Number,
      default: 2000
    }
  },
  data () {
    return {
      fallback: false
    }
  },
  watch: {
    loading (val) {
      this.fallback = val
      if (!this.ignoreTimeout) {
        setTimeout(() => {
          this.fallback = false
        }, this.timeoutFrame)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

import { render, staticRenderFns } from "./AnswerActionsConfigurator.vue?vue&type=template&id=730024d7&scoped=true&"
import script from "./AnswerActionsConfigurator.vue?vue&type=script&lang=js&"
export * from "./AnswerActionsConfigurator.vue?vue&type=script&lang=js&"
import style0 from "./AnswerActionsConfigurator.vue?vue&type=style&index=0&id=730024d7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730024d7",
  null
  
)

export default component.exports
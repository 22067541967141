<template>
  <div :class="['shortcuts', { 'shortcut-on-item' : shortcutsOnItem}]">
    <div class="shortcuts__header">
      <div class="d-flex align-items-center">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'shortcuts__header--icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.273 8.418H10.91l3.768-6.629A.528.528 0 0014.219 1H7.89a.527.527 0 00-.5.36l-3.164 9.528a.527.527 0 00.5.694H9.2l-2.849 6.683a.527.527 0 00.879.558l8.437-9.527a.527.527 0 00-.394-.878z" fill="#2B2727" opacity=".8"/></svg>
        <h1 class="shortcuts__header--title">{{ staticText.shortcutsLabel }}</h1>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!shortcutsOnItem" v-bind:class="'shortcuts__header--info-icon'" v-b-tooltip.hover.bottom="" :title="staticText.shortcutsDescriptionInfo" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
        <!-- Add new shortcut button -->
        <button
          v-if="canModify"
          id="add-new-shortcut-btn-202101231057"
          v-b-tooltip.hover.bottom
          :title="staticText.addNewShortcutBtn"
          class="shortcuts__header--add-btn"
          @click="addNewShortcut"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.32 5.22H6.78V1.68a.78.78 0 00-1.56 0v3.54H1.68a.78.78 0 000 1.56h3.54v3.54a.78.78 0 001.56 0V6.78h3.54a.78.78 0 000-1.56z" fill="#35375B"/></svg>
        </button>
      </div>
    </div>

    <div
      v-if="shortcuts && shortcuts.length > 0"
      id="configurator-shortcut-list-202103011137"
      class="shortcuts__body"
    >
      <draggable v-model="shortcuts"
                 v-bind="dragOptions"
                 :group="'shortcuts' + mainContainer.id"
                 :disabled="isShortcutLoading || !canModify"
                 handle=".drag-icon"
                 @start="drag=true"
                 @end="drag=false"
                 @change="handleShortcutsChanged"
      >
        <!--Shortcut Button-->
        <div
          v-for="(shortcut, index) in shortcuts"
          :key="'shortcut-'+index"
          class="shortcuts__item"
        >
          <button
            :id="`shortcut-btn-${shortcut.id}`"
            class="shortcuts__item--button"
            @click="shortcutClicked(shortcut, index)"
          >
            <div class="shortcuts__item--title-container">
              <svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'shortcuts__item--drag-icon drag-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.5 1.038a.833.833 0 111.667 0 .833.833 0 01-1.667 0zm3.333 0a.833.833 0 111.667 0 .833.833 0 01-1.667 0zM.5 4.372a.833.833 0 111.667 0 .833.833 0 01-1.667 0zm3.333 0a.833.833 0 111.667 0 .833.833 0 01-1.667 0zM.5 7.705a.833.833 0 111.667 0 .833.833 0 01-1.667 0zm3.333 0a.833.833 0 111.667 0 .833.833 0 01-1.667 0zM.5 11.038a.833.833 0 111.667 0 .833.833 0 01-1.667 0zm3.333 0a.833.833 0 111.667 0 .833.833 0 01-1.667 0zM.5 14.372a.833.833 0 111.667 0 .833.833 0 01-1.667 0zm3.333 0a.833.833 0 111.667 0 .833.833 0 01-1.667 0z" fill="#7F8197"/></svg>
              <h3
                :id="`shortcut-title-${shortcut.id}`"
                class="shortcuts__item--title"
              >
                {{ shortcut.name }}
              </h3>
              <b-tooltip
                v-if="isTextTruncated(`shortcut-title-${shortcut.id}`)"
                :target="`shortcut-title-${shortcut.id}`"
                triggers="hover"
                noninteractive
                custom-class="shortcuts-tooltip-20230317"
              >
                {{ shortcut.name }}
              </b-tooltip>
            </div>

            <!-- Delete Button -->
            <bao-delete-button
              v-if="canModify"
              :id="'shortcut-btn-remove-' + shortcut.id"
              :tooltip="staticText.removeShortcutLabel"
              :url="'/api/objectionchoices/' + shortcut.id"
              :noninteractiveTooltip="true"
              tooltip-placement="bottom"
              class="shortcuts__item--delete-btn"
              icon="fa fa-xs fa-trash global-color-slate-80"
              extra-class="delete-btn-20230316"
              @delete="handleShortcutDelete(index)"
              @deleted="handleShortcutsChanged"
              @error="error=>emitError(error)"
            >
              {{ staticText.warningModalText }}
            </bao-delete-button>
          </button>
        </div>
      </draggable>
    </div>

    <!-- Shortcut Modal -->
    <shortcuts-modal
      ref="shortcut-modal-202304181501"
      :can-modify="canModify"
      :main-container="mainContainer"
      :shortcuts-on-item="shortcutsOnItem"
      :parent-item-index="itemIndex"
      @update-last-saved="$emit('update-last-saved')"
      @shortcut-deleted="handleShortcutDeleted"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { tooltipDelay } from "@/config"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { playbookServices } from "../PlaybookServices"
import { isTextTruncated } from "./utils"

export default {
  name: "ShortcutsConfigurator",
  components: {
    BaoDeleteButton,
    draggable,
    ShortcutsModal: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ShortcutsModal" */ "./ShortcutsModal.vue")
  },
  props: {
    mainContainer: { // Item shortcut is attached to
      type: Object,
      required: true
    },
    itemIndex: {
      type: Number,
      default: 0,
      required: false
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    },
    shortcutsOnItem: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      windowService: window,
      tooltipDelay,
      drag: false,
      shortcuts: [],
      staticTextDefault: {
        removeShortcutLabel: "Remove shortcut",
        warningModalText: "Are you sure you want to delete this shortcut?",
        addNewShortcutBtn: "New Shortcut",
        shortcutsLabel: "Shortcuts",
        shortcutsDescriptionInfo: "Here you see all your shortcuts to frequently raised objections, frequently asked questions and similar."
      }
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    isShortcutLoading () {
      if (this.shortcuts) {
        return this.shortcuts.some(item => item.loading)
      }
      return false
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    mainContainer (newValue) {
      this.shortcuts = newValue.objections
    }
  },
  created () {
    this.shortcuts = this.mainContainer.objections
  },
  methods: {
    isTextTruncated,
    ...playbookServices.methods,
    addNewShortcut () {
      const defaultPlaybookItem = this.getDefaultPlaybookItem({ isObjection: true, order: 1 })
      const defaultMainContainer = this.getDefaultPlaybookItem({ isMainContainer: true })
      defaultMainContainer.children = [defaultPlaybookItem]
      const defaultShortcut = {
        name: "Item Name",
        order: this.shortcuts.length + 1,
        workflow: defaultMainContainer,
        type: "objection",
        talkscript_items: [this.mainContainer.id]
      }
      this.objectionWasSelected({ objection: defaultShortcut, mainContainer: this.mainContainer })
    },
    shortcutClicked (shortcut, index) {
      // this order/index is used when updating the item with new shortcut
      shortcut.order = index + 1
      this.objectionWasSelected({ objection: shortcut, mainContainer: this.mainContainer })
    },
    handleShortcutDeleted (deletedShortcut) {
      const shortcutIndex = this.shortcuts.findIndex(shortcut => shortcut.id === deletedShortcut.id)
      if (shortcutIndex === -1) return
      this.handleShortcutDelete(shortcutIndex)
      this.handleShortcutsChanged()
    },
    emitError (error) {
      this.$emit("error", error)
    },
    handleShortcutDelete (index) {
      this.shortcuts.splice(index, 1)
    },
    handleShortcutsChanged () {
      // Shortcuts being changed is handled differently for playbookItems and mainContainer
      // We can use handleOrderChange method as the shortcuts are already saved when added/deleted.
      // But handleOrderChange wont create an item version, hence we leave it on the parent component to handle which
      // method to call (update method if the item is playbookItem and handleOrderChange when item is mainContainer)
      // Creation of version on shortcut creation/deletion ensures that when user deletes the shortcut in the
      // playbook-item in future, he can still choose to come back to this version where the shortcut was added/deleted
      this.$emit("input", this.shortcuts)
    },
    objectionWasSelected (objectionData) {
      this.$refs["shortcut-modal-202304181501"].objectionWasSelected(objectionData)
    }
  }
}
</script>

<style scoped lang="scss">

.h-20 {
  height: 20%;
}

.list-group-item-action:focus {
  color: white;
  background-color: #007bff;
}

.list-group-item-action:hover {
  color: white;
  background-color: #007bff;
}

#delete-button-202101232242 {
  padding-left: 0;
}

.shortcuts {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 10px 6px 10px 16px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 0;
    &--icon {
      margin-right: 4px;
      path {
        opacity: 1;
        fill: $slate60;
      }
    }
    &--title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $slate;
      margin: 0;
    }
    &--info-icon {
      cursor: pointer;
      margin-right: 12px;
      path {
        opacity: 1;
        transition: 0.3s ease-in;
        fill: $slate60;
      }
      &:hover {
        path {
          fill: $slate;
        }
      }
    }
    &--add-btn {
      background-color: $slate80;
      box-shadow: 16px 12px 40px rgba(0, 0, 0, 0.05);
      border-radius: 40px;
      outline: none;
      border: none;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in;
      &:hover {
        background-color: $slate;
      }
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  &__body {
    height: 100%;
    overflow-y: auto;
    padding-right: 8px;
    margin-top: 10px;
  }
  &__item {
    width: 100%;
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
    &--button {
      background-color: $white80;
      border: 1px solid #D4D5DC;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      outline: none;
      padding: 8px;
      transition: 0.3s ease-in;
      &:hover {
        background-color: $white60;
      }
    }
    &--title-container {
      display: flex;
      align-items: center;
      width: calc(100% - 40px); // width is 100% minus width of delete btn + margin left
    }
    &--drag-icon {
      cursor: grab;
      margin-right: 8px;
      width: 6px;
    }
    &--title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $slate80;
      margin: 0;
      width: calc(100% - 14px); // width is 100% minus width of drag icon + margin right
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &--delete-btn {
      margin-left: 8px;
    }
  }
  &.shortcut-on-item {
    background-color: transparent;
    padding: 0;
    .shortcuts__header {
      padding: 0;
    }
    .shortcuts__body {
      padding-right: 0;
    }
  }
}

</style>

<style lang="scss">

.shortcuts-tooltip-20230317 {
  .tooltip-inner {
    padding: 12px !important;
    text-align: left;
    word-break: break-all;
  }
}

</style>

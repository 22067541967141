<template>
  <div class="alertContainer">
    <b-alert
      :show="showAlert"
      :variant="variant"
      :dismissible="dismissible"
    >
      <template #default>
        <div class="d-flex align-items-center p-1 pr-3">
          <img
            :src="iconSrcUrl"
            class="iconClass"
          />
          <slot name="alertMessage"><span class="ml-1" v-html="alertMessage"></span></slot>
        </div>
      </template>
      <template #dismiss>
        <span class="d-flex align-items-center"
              @click="handleClick"
        >
          <img
            src="@/assets/svgs/cancel-icon.svg"
            class="closeBtnIcon ml-2"
          />
        </span>
      </template>
    </b-alert>
  </div>
</template>

<script>

export default {
  name: "BaseAlert",
  data () {
    return {
      iconSrc: {
        danger: "/img/icons/error_icon.svg"
      }
    }
  },
  props: {
    showAlert: {
      default: true
    },
    alertMessage: {
      default: "Something went wrong. Check the input again."
    },
    variant: {
      default: "danger"
    },
    dismissible: {
      default: false
    }
  },
  computed: {
    iconSrcUrl () {
      return this.iconSrc[this.variant]
    }
  },
  methods: {
    handleClick () {
      this.$emit("dismissed")
    }
  }
}
</script>

<style lang="scss" scoped>

.iconClass {
  width: 14px;
}

.closeBtnIcon {
  width: 12px;
}

.alertContainer {
  display: flex;
  align-items: center;
  min-width: fit-content;
}

:deep(.alert) {
  border-radius: 6px;
  padding: 0 6px;
  margin-left: auto;
  margin-top: unset;
  margin-bottom: unset;

  & .close {
    padding: unset;
    color: inherit;
    bottom: 0;
    right: 4px;
  }
}

</style>

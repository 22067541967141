<template>
  <!-- Playbook Item Container -->
  <div class="mb-2">
    <!-- Playbook items -->
    <draggable v-model="items"
               v-bind="dragOptions"
               :handle="`.${PLAYBOOK_ITEM_DRAGGABLE_HANDLE}`"
               :disabled="playbookItemsLoading || !canModify"
               @start="drag = true"
               @end="drag = false"
               @change="orderWasChanged()"
    >
      <transition-group :name="!drag ? 'flip-list' : null"
                        type="transition"
                        tag="div"
      >
        <div :id="currentItem.uniqueId"
             v-for="(currentItem, index) in items"
             :key="index + 1"
             class="my-1"
        >
          <!-- Create/Link item buttons -->
          <div v-if="canModify">
            <new-item-controls
              v-if="insertButtonIndex===index"
              :playbook-items="items"
              :main-container-id="mainContainer.id"
              v-click-outside="setInsertButtonIndex"
              @create="addNewBeforeCurrentPlaybookItem(index, currentItem)"
              @link="selectedLibraryItem=>linkLibraryItemBeforePlaybookItem(selectedLibraryItem, index)"
            ></new-item-controls>
            <playbook-item-insert-button
              v-else
              :id="'playbook-item-insert-'+index"
              class="my-1"
              :current-item-index="index"
              @insert="setInsertButtonIndex(index)"
            ></playbook-item-insert-button>
          </div>

          <!-- Playbook loading completed -->
          <playbook-item
            :value="currentItem"
            :index="index"
            :item-types="itemTypes"
            :main-container="mainContainer"
            :selected-objection="selectedObjection"
            :base-url="baseUrl"
            :can-be-edited="!currentItem.is_library_item"
            :isOpenFromShortcutsModal="isOpenFromShortcutsModal"
            @handle-version-changed="item => $emit('handle-version-changed', item)"
            @handle-playbook-item-changed="handlePlaybookItemChanged"
            @update-last-saved="$emit('update-last-saved')"
            @delete-item="deleteItem"
            @order-was-changed="orderWasChanged"
            @emit-error="emitError"
            @get-selected-object="getSelectedObject"
            @shortcut-deleted="$emit('shortcut-deleted')"
          />

        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>

import draggable from "vuedraggable"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import { baoDelayService } from "@/apps/call"
import PlaybookItem from "./PlaybookItem.vue"
import { mapActions, mapGetters } from "vuex"
import appInfo from "@/apps/talkscript/index"
import PlaybookItemInsertButton from "./components/PlaybookItemInsertButton"
import NewItemControls from "@/apps/talkscript/components/NewItemControls"
import axios from "axios"
import vClickOutside from "v-click-outside"
import { PLAYBOOK_ITEM_DRAGGABLE_HANDLE } from "./components/utils"
import _isEqual from "lodash/isEqual"

export default {
  name: "PlaybookConfiguratorItem",
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    draggable,
    PlaybookItem,
    PlaybookItemInsertButton,
    NewItemControls
  },
  props: {
    playbookItems: {
      type: Array, // playbook items
      required: true,
      default: () => []
    },
    mainContainer: { // parent of the playbook items
      type: Object,
      required: true
    },
    selectedObjection: {
      type: Object
    },
    itemTypes: {
      type: Array,
      required: true
    },
    isOpenFromShortcutsModal: {
      type: Boolean,
      default: false,
      required: false
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      axios,
      items: [],
      drag: false,
      insertButtonIndex: null,
      PLAYBOOK_ITEM_DRAGGABLE_HANDLE,
      staticTextDefault: {
        crmObjectDefinitionError: "The following error occurred while trying to fetch the CRM object definitions for",
        addNewItemError: "Error occurred while adding new item. <br/>"
      }
    }
  },
  computed: {
    ...mapGetters({
      fieldDefinitions: "crmStore/getFieldDefinitions",
      objectDefinitionError: "crmStore/getObjectDefinitionError"
    }),
    baseUrl () {
      return appInfo.apiUrl.playbookItem
    },
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    playbookItemsLoading () {
      return this.items.some(item => item.loading)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    playbookItems: {
      handler (value, oldValue) {
        if (!_isEqual(value, oldValue)) {
          this.items = value
          this.checkLinkedFields()
        }
      },
      deep: true
    },
    items: {
      handler (value, oldValue) {
        if (!_isEqual(value, oldValue)) this.$emit("items-changed", value)
      },
      deep: true
    },
    objectDefinitionError (val, oldVal) {
      if (val) {
        const fullErrorMsg = "<p>" + this.staticText.crmObjectDefinitionError + " " +
          val.label + ": </p><p>" + this.extractErrorMessage(val.error) + "</p>"
        this.emitError(fullErrorMsg)
      }
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    ...playbookServices.methods,
    ...baoDelayService.methods,
    ...mapActions({
      getCRMObjectLink: "crmStore/getCRMObjectLink",
      retrieveFieldDefinitions: "crmStore/retrieveFieldDefinitions"
    }),
    setUp () {
      this.items = this.playbookItems
      this.getCRMObjectLink().then(() => {
        this.checkLinkedFields()
      })
    },
    setInsertButtonIndex (index = null) {
      this.insertButtonIndex = index
    },
    emitError (error, item) {
      const errorDetails = {
        message: error
      }
      if (item) {
        const extraInfo = {
          item,
          position: this.getItemPosition(item),
          itemLink: item.uniqueId
        }
        Object.assign(errorDetails, extraInfo)
      }
      this.$emit("error", errorDetails)
    },
    trackBy (item) {
      return item.id ? item.id : item.talkscript
    },

    deleteItem (index) {
      if (this.selectedObjection) this.$emit("delete-shortcut-item", index)
      else this.items.splice(index, 1)
    },

    handlePlaybookItemChanged (item = null) {
      /**
       * if item available, This method emits the item and position of the changed item so that the parent component
       * can validate and show error at the correct position
       * else, it only emits that item was changed so that the parent component can update the
       * last saved of playbook in frontend
       */
      const itemPosition = item ? this.getItemPosition(item) : -1
      this.$emit("item-changed", item, itemPosition)
    },
    getItemPosition (item) {
      return this.findItemIndex(this.items, item) + 1
    },
    addNewBeforeCurrentPlaybookItem (newItemIndex) {
      // The index is the index in the array and the itemPosition is the order of the item in the list of items.
      this.setInsertButtonIndex()

      if (this.isOpenFromShortcutsModal) this.$emit("add-item-before-current", newItemIndex)
      else {
        return new Promise((resolve, reject) => {
          const itemPosition = newItemIndex + 1
          const item = this.getDefaultPlaybookItem({ parent: this.mainContainer.id, order: itemPosition, isObjection: false })
          this.addNewPlaybookItem(this.mainContainer, [item], this.items)
            .then(() => {
              this.handlePlaybookItemChanged()
              resolve()
            }).catch(error => {
              const errorMsg = this.staticText.addNewItemError + this.extractErrorMessage(error)
              this.emitError(errorMsg)
              reject(errorMsg)
            })
        })
      }
    },
    linkLibraryItemBeforePlaybookItem (selectedLibraryItem, newItemIndex) {
      this.setInsertButtonIndex()
      if (!this.isOpenFromShortcutsModal) {
        this.items.splice(newItemIndex, 0, selectedLibraryItem)

        this.linkPlaybookItem(selectedLibraryItem, this.mainContainer, newItemIndex)
          .then(() => { this.handlePlaybookItemChanged() })
          .catch(error => {
            console.error(error)
            this.items.splice(newItemIndex, 1)
          })
      } else this.$emit("add-library-item-before-current", selectedLibraryItem, newItemIndex)
    },
    orderWasChanged () {
      if (this.selectedObjection) return

      return this.handleOrderChanged(this.mainContainer, this.items).then(() => {
        this.handlePlaybookItemChanged()
      }).catch(error => {
        this.emitError(error)
      })
    },
    checkLinkedFields () {
      const usedCRMObjectLinks = []
      this.playbookItems.forEach(item => {
        if (item.linked_field && item.linked_field.crm_object_link && item.item_type === "crmlink") {
          usedCRMObjectLinks.push(item.linked_field.crm_object_link)
        }
      })
      this.retrieveFieldDefinitions(usedCRMObjectLinks)
    },
    getSelectedObject (val) {
      this.getCRMObjectLink().then(this.checkLinkedFields)
      this.retrieveFieldDefinitions([val])
    }
  }
}
</script>

<style scoped lang="scss">

.nav-pills .nav-link.active {
  background-color: $white;
  border-radius: 25px;
  border: 1px solid #007bff;
}

</style>

<template>
  <b-modal id="playbook-navigation-warning-modal"
           v-model="showWarning"
           :title="title"
           no-close-on-esc
           no-close-on-backdrop
           hide-footer
  >
    <div class="d-flex">
      <img id="alert-icon-202103210125"
           src="/img/icons/exclamation-triangle-solid.svg"
           width="50vh"
           class="m-3"
      />

      <div class="m-2">
        <slot></slot>
      </div>
    </div>
    <div class="mt-4 text-center d-flex justify-content-center">
      <b-btn id="cancel-navigation-btn-202012292311"
             variant="secondary"
             class="mx-3"
             @click="$emit('cancel' )"
      >
        {{staticText.cancelNavigationBtn}}
      </b-btn>

      <b-btn id="navigate-btn-202012292311"
             variant="danger"
             class="mx-3"
             @click="$emit('ok' )"
      >
        {{staticText.navigateBtn}}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "NavigationWarningButtons",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      default: "",
      required: false
    }
  },
  data () {
    return {
      showWarning: false,
      staticTextDefault: {
        cancelNavigationBtn: "Cancel",
        navigateBtn: "Navigate"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    showWarning () {
      this.$emit("input", this.showWarning)
    },
    value () {
      this.showWarning = this.value
    }
  },
  mounted () {
    this.showWarning = this.value
  }
}
</script>

<style scoped lang="scss">
#playbook-navigation-warning-modal___BV_modal_outer_{
  z-index:10000000 !important;
}
</style>

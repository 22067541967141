<template>
  <div>
    <div class="access-settings-container font-weight-bold">{{ staticText.title }}</div>
    <div>
      <b-form-radio-group v-model="sharingType"
                          value-field="value"
                          html-field="text"
                          class="mt-2"
                          stacked
                          :options="permissionOptions"
                          @change="handleInput"
      ></b-form-radio-group>
      <BaoVueMultiSelect
        v-if="customSharingType"
        v-model="selectedUsers"
        :loading="loading"
        :options="groupMembers"
        :placeholder="staticText.selectUserPlaceholder"
        :multiple="true"
        :show-labels="false"
        :closeOnSelect="false"
        track-by="email"
        label="full_name"
        openDirection="bottom"
        @open="getUserOptions"
        @input="sharePlaybook"
        class="access-settings-container__user-input-selector mt-2"
      ></BaoVueMultiSelect>
    </div>
  </div>

</template>

<script>
import axios from "axios"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { mapGetters } from "vuex"

export default {
  name: "PlaybookAccessSettings",
  components: { BaoVueMultiSelect },
  data () {
    return {
      axios,
      loading: false,
      groupMembersLoaded: false,
      sharingType: "team",
      selectedUsers: [],
      groupMembers: [],
      staticTextDefault: {
        meText: "Only <b>you</b> can see/use this playbook",
        teamText: "Your <b>whole team</b> can see/use this playbook",
        customText: "<b>Selected users</b> of your team can see/use this playbook",
        selectUserPlaceholder: "Select a User",
        title: "Access"
      }
    }
  },
  props: {
    playbook: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.playbook && this.playbook.shared_with && this.playbook.shared_with.type) {
      this.sharingType = this.playbook.shared_with.type
      if (this.customSharingType) {
        this.selectedUsers = this.playbook.shared_with.users
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user"
    }),
    customSharingType () {
      return this.sharingType === "custom"
    },
    permissionOptions () {
      return [
        { value: "me", text: this.staticText.meText },
        { value: "team", text: this.staticText.teamText },
        { value: "custom", text: this.staticText.customText }
      ]
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    handleInput () {
      if (this.customSharingType && !this.selectedUsers.length) return
      this.sharePlaybook()
    },
    sharePlaybook () {
      try {
        const url = `/api/talkscripts/${this.playbook.id}/share`
        const data = { sharing_type: this.sharingType }
        if (this.customSharingType) {
          data.user_ids = this.selectedUsers.map(item => item.pk)
        }
        this.axios.put(url, data)
      } catch (err) {
        console.debug(err)
      }
    },
    async getUserOptions () {
      if (this.groupMembersLoaded) return
      try {
        this.loading = true
        const userUrl = `/api/users/get_group_members?is_active=true&id!=${this.currentUser.pk}`
        this.axios.get(userUrl).then(response => {
          this.groupMembers = response.data
          this.groupMembersLoaded = true
          this.loading = false
        })
      } catch (err) {
        this.loading = false
        console.debug(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.access-settings-container {
  display: flex;
  &__user-input-selector {
    width: 408px;
    margin-left: 24px;
  }
}

:deep(.multiselect__tags) {
  padding: 18px 35px 8px 8px !important;
}
</style>

<template>
  <div v-if="currentItem">
    <div class="my-2 row align-items-center">
      <div class="col-2 mb-auto">
        {{ staticText.additionalPlaybooks }}
      </div>

      <div class="col-10">
        <vue-multiselect
          :id="'additional-playbook-' + currentItem.uniqueId"
          :options="additionalPlaybooks"
          :show-labels="false"
          :value="selectedAdditionalPlaybooks(currentItem)"
          :multiple="true"
          :custom-label="item => item.name"
          :placeholder="staticText.selectAdditionalPlaybooks"
          :disabled="!canModify"
          track-by="id"
          @select="
            (item, id) =>
              handleAdditionalPlaybooks(currentItem, item, id, false)
          "
          @remove="
            (item, id) => handleAdditionalPlaybooks(currentItem, item, id, true)
          "
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="custom__tag d-flex justify-content-between mb-1">
              <span class="w-100">
                <a :href="'/templates/edit/' + option.id" target="_blank">
                  <i class="mr-2 fas fa-book"></i>
                  <span class="option__small">
                    {{ option.name }}
                  </span>
                </a>
              </span>

              <span class="custom__remove" @click.stop="remove(option)">
                <i class="far fa-times-circle"></i>
              </span>
            </span>
          </template>
        </vue-multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueMultiselect from "vue-multiselect"

export default {
  data () {
    return {
      staticTextDefault: {
        additionalPlaybooks: "Add Playbooks",
        selectAdditionalPlaybooks: "Select additional playbooks"
      }
    }
  },
  components: {
    VueMultiselect
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
      required: true
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      additionalPlaybooks: "playbook/getAllPlaybooks"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    selectedAdditionalPlaybooks (item) {
      return item.additional_talkscripts.map(item => {
        return {
          id: item.talkscript,
          name: item.name
        }
      })
    },
    handleAdditionalPlaybooks (item, playbook, id, removed) {
      if (removed) {
        const dataChoice = item.additional_talkscripts.filter(
          item => item.talkscript === playbook.id
        )[0]
        if (!dataChoice) {
          throw new Error("Selected dataChoice could not be found.")
        }
        item.additional_talkscripts.splice(
          item.additional_talkscripts.indexOf(dataChoice),
          1
        )
        this.$emit("handle-playbook-item-changed", item)
        return
      }
      const data = {
        name: playbook.name,
        talkscript: playbook.id,
        talkscript_items: [item.id]
      }
      item.additional_talkscripts.push(data)
      this.$emit("handle-playbook-item-changed", item)
    }
  }
}
</script>
